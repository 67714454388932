import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ImageModal } from "../../components";
import { useLazyGetSpecificPropertyByIdQuery } from "../../services/property";
import { MarginOutlined } from "@mui/icons-material";

const PropertyRequestDetails = () => {
  const { _id } = useParams();
  const { state } = useLocation();
  const [fetchApi, { isLoading }] = useLazyGetSpecificPropertyByIdQuery();
  const navigate = useNavigate();
  const [ByIdDetails, setByIdDetails] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleStatus = (status: number) => {
    switch (status) {
      case 7:
        return 'MOU documents';
      case 8:
        return 'Form F';
      case 9:
        return 'New Title Deed';
      case 10:
        return 'Old Title Deed';
      case 13:
        return 'MOU or Form F';
      case 14:
        return 'Title Deed or Spa';
      case 15:
        return 'Tenancy Agreement';
        case 16:
          return 'Emirate ID';
      default:
        return 'Unknown';
    }
  };

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);




  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Property Listing Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-property-requests", { state: state?.tab })}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  {ByIdDetails?.images?.length > 0 && (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Images</Typography>
                        <div className="profile_img_grid">
                          {ByIdDetails.images.map((image: string, index: number) => (
                            <figure className="profile_img" style={{ borderRadius: 0 }}>
                              <img
                                style={{ borderRadius: 0 }}
                                src={image}
                                alt={`property-${index}`}
                              />
                              <div className="overlay">
                                <img
                                  onClick={() => handleOpenModal(image)}
                                  src='/static/images/visibility.png' alt='img'
                                />
                              </div>
                            </figure>
                          ))}
                        </div>
                      </Box>
                    </Grid>
                  )}
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Name</Typography>
                      <Typography component="p">{ByIdDetails?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Owner</Typography>
                      <Typography component="p">{ByIdDetails?.userId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">{ByIdDetails?.price || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Category</Typography>
                      <Typography component="p">{ByIdDetails?.categoryId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Type</Typography>
                      <Typography component="p">{ByIdDetails?.propertyTypeId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Beds</Typography>
                      <Typography component="p">{ByIdDetails?.beds || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Baths</Typography>
                      <Typography component="p">{ByIdDetails?.baths || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Address</Typography>
                      <Typography component="p">{ByIdDetails?.address || "-"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Key Features</Typography>
                      <Typography component="p">{ByIdDetails?.keyFeature || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Specifications</Typography>
                      <Typography component="p">{ByIdDetails?.specifications || "-"}</Typography>
                    </Box>
                  </Grid>
                  {ByIdDetails?.amenitiesId?.length > 0 && (

                    <Grid item xs={12}>
                      <Typography component="h5" >Amenities</Typography>
                      <Grid container spacing={2}>
                        {ByIdDetails.amenitiesId.map((amenity: any, index: number) => (
                          <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                            <Typography component="p">{amenity?.name}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {ByIdDetails?.documents?.length ? (
          <div className="dashboard" style={{ paddingTop: 35 }}>
            <h2 className="mn_hdng">Documents</h2>
          </div>
        ) : ("")}

        {ByIdDetails?.documents?.length ? (
          <Card className="cards">
            {ByIdDetails?.documents?.map((item: any) => {
              return (
                <div className="single_doc">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="heading">
                      <Typography className="document_head" variant="h5">
                        <strong>Document Name: </strong>  {handleStatus(Number(item?.documentType))}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="docs_div">
                        <figure>
                          <img
                            src='/static/images/pdf.png'
                            alt="Front Document"
                            style={{ cursor: "pointer", maxWidth: "300px" }}
                          />
                          <div className="overlay">
                            <img
                              onClick={() => { window.open(item?.frontImage) }}
                              src='/static/images/visibility.png' alt='img'
                            />
                          </div>
                        </figure>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              )
            })}
          </Card>
        ) : ("")}
      </div>
      <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />

    </div>

  );
};

export default PropertyRequestDetails;
