import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  TextField,
  Popper,
  ClickAwayListener,
  Paper,
  Chip,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import SearchBar2 from "../../components/SearchBar2";
import { useEditUserMutation, useDeleteByIduserMutation, useLazyGetAllUserQuery } from "../../services/users";
import Pagination from "../../components/Pagination";

import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, User } from "../../types/General";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";

import { useLazyExportNotifyQuery, useGetAllNotifyEmailQuery } from "../../services/emails";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { Clear } from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ManageEmails = () => {

  const [rows, setRows] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [editUSERbyId] = useEditUserMutation();
  const [deleteUserById] = useDeleteByIduserMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });


  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const [exportCsv] = useLazyExportNotifyQuery();
  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({

      }).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };


  const {data,isError,isSuccess,isLoading}=useGetAllNotifyEmailQuery({
    page: page, search: debouncedSearchTerm.trim(), limit: limit, range: {
      from: range?.from ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
      to: range?.to ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : ""
    }
  })


  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.notifyMe)
      setTotalCount(res?.count );
    }
  }, [isSuccess, data]);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Emails</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className  ="control_group" style={{ width: "250px" }}>
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end" style={{ marginLeft: -50 }}>
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Popper
                open={open1}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                    <div
                      style={{
                        padding: 16,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Chip
                          label="Last Week"
                          variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                          onClick={() => handleChipClick("lastWeek")}
                        />
                        <Chip
                          label="Last Month"
                          variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                          onClick={() => handleChipClick("lastMonth")}
                        />
                        <Chip
                          label="Last Year"
                          variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                          onClick={() => handleChipClick("lastYear")}
                        />
                      </div>

                      <DayPicker
                        className="react-day-picker"
                        mode="range"
                        selected={range}
                        onSelect={handleSelect}
                        // numberOfMonths={10}
                        month={month}
                        onMonthChange={handleMonthChange}
                      // styles={{
                      //   months: { display: "flex", gap: "16px" },
                      //   month: { margin: 0 },
                      // }}
                      />
                    </div>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </Box>
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={handleExportCsv}
              >
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No email found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row: any, i: any) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>

                      <TableCell>{row?.email || "-"}</TableCell>



                    </TableRow>
                  ))
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}

    </div>
  );
};

export default ManageEmails;
