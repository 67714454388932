import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};



interface CategoryResponse extends CommonResponseType {
  data: any;
}

interface QueryParams {
  userId?: any;
  page?: any
}

export const amenitiesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddAmenities: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.amenities,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GETAMEN"]
    }),
    getAllAmenities: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit?: number }>({
      query: ({ page, search, limit }) => {
        let url = `${END_POINTS.amenities}?page=${page}&search=${search}&limit=${limit}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["GETAMEN"]
    }),


    getSpecificamenitiesById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId, page }) => {
        let url = `${END_POINTS.amenities}/${userId}`;
        if (page !== undefined) {
          url += `?page=${page}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),



    Editamenities: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.amenities}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GETAMEN"]
    }),



    deleteByIdAmenities: builder.mutation<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.amenities}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags:["GETAMEN"]
    }),

  }),
});

export const {
  usePostAddAmenitiesMutation,
  useLazyGetAllAmenitiesQuery,
  useGetAllAmenitiesQuery,
  useLazyGetSpecificamenitiesByIdQuery,
  useEditamenitiesMutation,
  useDeleteByIdAmenitiesMutation
} = amenitiesApi;
