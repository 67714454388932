import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";

import MainContainer from "../../layout/MainContainer";
import AgentProperty from "../../features/agents/Property";
import AgentDetails from "../../features/agents/AgentDetails";
import AgentMessages from "../../features/agents/AgentMessage";
import TokenHistoryAgent from "../../features/agents/TokenHistoryIndex";
import AgentReels from "../../features/agents/reelsByAgent";
import AgentInsights from "../../features/agents/CompanyInsights";
import CompanyInsights from "../../features/company/PerformanceInsights";
import { useLazyGetPerformanceInsightsQuery } from "../../services/performace";
import PerformanceOverview from "../../features/performanceOverview";
import LeadsInsight from "../../features/leadsInsight";
import SuperAgentCriteria from "../../features/agentInsights/superAgentCriteria";
import AgentTransactions from "../../features/agents/Transactions";
import moment from "moment";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AgentDetail = () => {
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const { _id } = useParams();
  const [getData] = useLazyGetPerformanceInsightsQuery();
  const [leadsSelectedTime, setLeadsSelectedTime] = useState("3");
  const [majorLineChartData, setMajorLineChartData] = useState<
    {
      name: string;
      count: number;
      emailCount: number;
      inAppChatCount: number;
      whatAppCount: number;
      phoneCount: number;
    }[]
  >([]);
  const [leadsSelectedApp, setLeadsSelectedApp] = useState("");

  const [displayTextData, setDisplayTextData] = useState<any>({
    whatsapp: {},
    phone: {},
    email: {},
    inApp: {},
  });
  const convertPieChartPercent = (
    data: { label: string; value: number; color: string }[]
  ) => {
    const total = data.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.value);
    }, 0);
    if (total === 0) return data;
    return data?.map((data2) => ({
      label: data2?.label,
      value: ((data2?.value / total) * 100).toFixed(2) || 0,
    }));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [pieChartData, setPieChartData] = useState<
    { label: string; value: number; color: string }[]
  >([]);
  const [barRankData, setBarRankData] = useState<any>({});

  const [overallGraphData, setOverallGraphData] = useState({
    listingsData: [
      { name: "Standard", value: 0, percentage: 0 },
      { name: "Featured", value: 0, percentage: 0 },
      { name: "Premium", value: 0, percentage: 0 },
    ],
    listingsDataTotal: 0,
    impressionsData: [
      { name: "Standard", value: 0, percentage: 0 },
      { name: "Featured", value: 0, percentage: 0 },
      { name: "Premium", value: 0, percentage: 0 },
    ],
    impressionsDataTotal: 0,
    clicksData: [
      { name: "Standard", value: 0, percentage: 0 },
      { name: "Featured", value: 0, percentage: 0 },
      { name: "Premium", value: 0, percentage: 0 },
    ],
    clicksDataTotal: 0,
  });

  const getLeadsInsight = async ({ startDate, endDate }: any) => {
    try {
      const response = await getData({
        leadsFilterType: leadsSelectedTime,
        leadType: leadsSelectedApp,
        type: 4,
        id: _id,
        startDate: startDate
          ? moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: endDate
          ? moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setPieChartData([
          {
            label: "Whatsapp",
            value: response?.data?.whatAppPercentage,
            color: "#020302",
          },
          {
            label: "Calls",
            value: response?.data?.phoneLeadsPercentage,
            color: "#575856",
          },
          {
            label: "In-App Chat",
            value: response?.data?.inAppChatLeadsPercentage,
            color: "#6c997e",
          },
          {
            label: "Email",
            value: response?.data?.emailLeadsPercentage,
            color: "#98a29c",
          },
        ]);
        setMajorLineChartData(response?.data?.graphData?.userData);
        setDisplayTextData({
          email: {
            emailLeads: response?.data?.emailLeads,
            emailLeadsNotreplied: response?.data?.emailLeadsNotreplied,
            emailLeadsReplied: response?.data?.emailLeadsReplied,
            emailLeadsResponseRate: response?.data?.emailLeadsResponseRate,
            emailLeadsResponseTime: response?.data?.emailLeadsResponseTime,
          },
          whatsapp: {
            whatsappLeads: response?.data?.whatsappLeads,
            whatAppNotreplied: response?.data?.whatAppNotreplied,
            whatAppReplied: response?.data?.whatAppReplied,
            whatAppResponseRate: response?.data?.whatAppResponseRate,
            whatAppResponseTime: response?.data?.whatAppResponseTime,
          },
          phone: {
            phoneLeads: response?.data?.phoneLeads,
            phoneLeadsAns: response?.data?.phoneLeadsAns,
            phoneLeadsAvg: response?.data?.phoneLeadsAvg,
            phoneLeadsCancel: response?.data?.phoneLeadsCancel,
            phoneLeadsUnans: response?.data?.phoneLeadsUnans,
          },
          inApp: {
            inAppChatLeads: response?.data?.inAppChatLeads,
            inAppChatLeadsPercentage: response?.data?.inAppChatLeadsPercentage,
            inAppChatLeadsNotreplied: response?.data?.inAppChatLeadsNotreplied,
            inAppChatLeadsResponseRate:
              response?.data?.inAppChatLeadsResponseRate,
            inAppChatLeadsResponseTime:
              response?.data?.inAppChatLeadsResponseTime,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getOverallInsight = async () => {
    try {
      const response = await getData({
        type: 1,
        id: _id,
      }).unwrap();
      if (response?.statusCode == 200) {
        setOverallGraphData({
          listingsData: [
            {
              name: "Standard",
              value: response?.data?.standard,
              percentage:
                (response?.data?.standard /
                  (response?.data?.premium +
                    response?.data?.featured +
                    response?.data?.standard)) *
                  100 || 0,
            },
            {
              name: "Featured",
              value: response?.data?.featured,
              percentage:
                (response?.data?.featured /
                  (response?.data?.premium +
                    response?.data?.featured +
                    response?.data?.standard)) *
                  100 || 0,
            },
            {
              name: "Premium",
              value: response?.data?.premium,
              percentage:
                (response?.data?.premium /
                  (response?.data?.premium +
                    response?.data?.featured +
                    response?.data?.standard)) *
                  100 || 0,
            },
          ],
          listingsDataTotal: response?.data?.totalListings,
          impressionsData: [
            {
              name: "Standard",
              value: response?.data?.standardImpressions,
              percentage:
                (response?.data?.standardImpressions /
                  (response?.data?.premiumImpressions +
                    response?.data?.featuredImpressions +
                    response?.data?.standardImpressions)) *
                  100 || 0,
            },
            {
              name: "Featured",
              value: response?.data?.featuredImpressions,
              percentage:
                (response?.data?.featuredImpressions /
                  (response?.data?.premiumImpressions +
                    response?.data?.featuredImpressions +
                    response?.data?.standardImpressions)) *
                  100 || 0,
            },
            {
              name: "Premium",
              value: response?.data?.premiumImpressions,
              percentage:
                (response?.data?.premiumImpressions /
                  (response?.data?.premiumImpressions +
                    response?.data?.featuredImpressions +
                    response?.data?.standardImpressions)) *
                  100 || 0,
            },
          ],
          impressionsDataTotal: response?.data?.impressions,
          clicksData: [
            {
              name: "Standard",
              value: response?.data?.standardClicks,
              percentage:
                (response?.data?.standardClicks /
                  (response?.data?.standardClicks +
                    response?.data?.featuredClicks +
                    response?.data?.premiumClicks)) *
                  100 || 0,
            },
            {
              name: "Featured",
              value: response?.data?.featuredClicks,
              percentage:
                (response?.data?.featuredClicks /
                  (response?.data?.standardClicks +
                    response?.data?.featuredClicks +
                    response?.data?.premiumClicks)) *
                  100 || 0,
            },
            {
              name: "Premium",
              value: response?.data?.premiumClicks,
              percentage:
                (response?.data?.premiumClicks /
                  (response?.data?.standardClicks +
                    response?.data?.featuredClicks +
                    response?.data?.premiumClicks)) *
                  100 || 0,
            },
          ],
          clicksDataTotal: response?.data?.listingClicks,
        });
        setBarRankData({
          bars: response?.data?.bars,
          rank: {
            listing: response?.data?.totalListingsRank,
            impression: response?.data?.impressionsRank,
            click: response?.data?.listingClicksRank,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Agent Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-agents", { state: state?.tab });
            }}
          >
            Back
          </Button>
        </div>

        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              {/* <Tab label="Manual Payouts" {...a11yProps(0)} /> */}
              <Tab label="Profile Details" {...a11yProps(0)} />
              <Tab label="Properties" {...a11yProps(1)} />
              <Tab label="Messages" {...a11yProps(2)} />
              <Tab label="Token History" {...a11yProps(3)} />
              <Tab label="Reels" {...a11yProps(4)} />
              <Tab label="Transactions" {...a11yProps(5)} />
              {state?.insights === true ? (
                <Tab label="Titan Insights" {...a11yProps(6)} />
              ) : (
                ""
              )}
              {state?.insights === true ? (
                <Tab label="Overall Performance" {...a11yProps(7)} />
              ) : (
                ""
              )}
              {state?.insights === true ? (
                <Tab label="Listing Performance" {...a11yProps(8)} />
              ) : (
                ""
              )}
              {state?.insights === true ? (
                <Tab label="Leads Insights" {...a11yProps(9)} />
              ) : (
                ""
              )}
              {/* {state?.insights === true ? <Tab label="Super Agent Criteria" {...a11yProps(10)} /> : ""} */}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <AgentDetails />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <AgentProperty />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <AgentMessages />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <TokenHistoryAgent />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <AgentReels />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <AgentTransactions />
            </CustomTabPanel>
            {state?.insights === true ? (
              <>
                <CustomTabPanel value={value} index={6}>
                  <AgentInsights />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={7}>
                  <CompanyInsights
                    getOverallInsight={getOverallInsight}
                    graphData={overallGraphData}
                    showRanks={true}
                    barRankData={barRankData}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={8}>
                  <PerformanceOverview />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={9}>
                  <LeadsInsight
                    filter={leadsSelectedTime}
                    setFilter={setLeadsSelectedTime}
                    pieChartData={convertPieChartPercent(pieChartData)}
                    majorLineChartData={majorLineChartData}
                    getLeadsInsight={getLeadsInsight}
                    setLeadsSelectedApp={setLeadsSelectedApp}
                    displayTextData={displayTextData}
                    leadsSelectedApp={leadsSelectedApp}
                  />
                </CustomTabPanel>
                {/* <CustomTabPanel value={value} index={10}>
                  <SuperAgentCriteria />
                </CustomTabPanel> */}
              </>
            ) : (
              ""
            )}
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default AgentDetail;
