import React, { useEffect, useLayoutEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    Switch,
    Button,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography,
    Tabs,
    Tab,
    TextField,
    Popper,
    ClickAwayListener,
    Paper,
    Chip,
    InputAdornment,
    Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { Clear } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import SearchBar2 from "../../../components/SearchBar2";
import { isValidInput } from "../../../utils/validation";
import Pagination from "../../../components/Pagination";
import { useLazyGetAllPaymentRequestQuery, usePostPaymentReasonMutation } from "../../../services/affiliateMembers";
import { errorToast, successToast } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import Loader from "../../../helpers/constants/Loader";
import ReasonModal from "../../../components/RejectReason";
import { getAgentCount, getCompanyCount, getCompanyRequestCount, getPaymentRequestCount, getPropertyRequestCount, getShortTermRequestCount, setRequestCount } from "../../../reducers/generalSlice";
import FeedbackDetails from "../../../components/FeedbackDetails";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ManagePaymentRequest = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const agentCount = useAppSelector(getAgentCount);
    const companyCount = useAppSelector(getCompanyCount);
    const companyRequestCount = useAppSelector(getCompanyRequestCount);
    const shortTermRequestCount = useAppSelector(getShortTermRequestCount);
    const propertyRequestCount = useAppSelector(getPropertyRequestCount);
    const paymentRequestCount = useAppSelector(getPaymentRequestCount);
    const [rows1, setRows1] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [getAllRequests] = useLazyGetAllPaymentRequestQuery();
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount1] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    let totalPages = Math.ceil(totalCount / limit);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState<string>("")
    const [openReject, setOpenReject] = useState(false);
    const [editCompanybyId] = usePostPaymentReasonMutation();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [rejectionReason, setRejectionReason] = useState<string>("");
    const [receiver, setReceiver] = useState("1");
    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRows1([]);
    };
    const { state } = useLocation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setPage(1);
    };

    const [open1, setOpen1] = useState(false); // Control calendar visibility
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedChip, setSelectedChip] = useState("");
    const [month, setMonth] = useState(new Date());
    const [range, setRange] = useState<{
        from: Date | undefined;
        to: Date | undefined;
    }>({
        from: undefined,
        to: undefined,
    });

    const handleClose = () => {
        setOpen1(false);
    };
    const [dataCount, setDataCount] = useState({
        pendingRequests: 0,
        aproovedRequests: 0,
        rejectedRequests: 0,
    });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen1(true);
    };

    const getFormattedRange = () => {
        if (range?.from && range?.to) {
            return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
                range.to
            ).format("MM/DD/YYYY")}`;
        } else if (range?.from) {
            return moment(range.from).format("MM/DD/YYYY");
        }
        return "";
    };

    const handleSelect = (selectedRange: any) => {
        if (selectedRange === undefined) {
            setRange({ from: undefined, to: undefined });
        } else {
            setRange(selectedRange);
        }
    };

    const handleChipClick = (value: any) => {
        if (value === selectedChip) {
            setSelectedChip("");
            setRange({ from: undefined, to: undefined });
            setMonth(moment().toDate());
            return;
        }
        setSelectedChip(value);

        let newRange = { from: moment().toDate(), to: moment().toDate() };
        const today = moment();

        if (value === "lastMonth") {
            newRange = {
                from: moment().subtract(1, "months").startOf("month").toDate(),
                to: moment().subtract(1, "months").endOf("month").toDate(),
            };
        } else if (value === "lastWeek") {
            newRange = {
                from: moment().subtract(7, "days").startOf("day").toDate(),
                to: today.toDate(),
            };
        } else if (value === "lastYear") {
            newRange = {
                from: moment().subtract(1, "years").startOf("year").toDate(),
                to: moment().subtract(1, "years").endOf("year").toDate(),
            };
        }
        setRange(newRange);
        setMonth(newRange.from);
    };

    const handleMonthChange = (newMonth: any) => {
        setMonth(newMonth);
    };

    const getRequest = async () => {
        try {
            const res = await getAllRequests({
                page: page,
                limit: limit,
                range: {
                    from: range?.from || "",
                    to: range?.to || ""
                },
                search: debouncedSearchTerm?.trim(),
                status: value === 0 ? 1 : value === 1 ? 2 : 3,
            }).unwrap();
            if (res?.statusCode === 200) {
                setRows1(res?.data?.requests || [])
                setDataCount({
                    pendingRequests: res?.data?.pendingRequests,
                    aproovedRequests: res?.data?.aproovedRequests,
                    rejectedRequests: res?.data?.rejectedRequests
                })
            }
        } catch (error: any) {
            errorToast(error?.data?.message)
        }
    }



    const [selectedCompany, setSelectedCompany] = useState<any>();

    const handleApprove = async (userId: any) => {
        const body = {
            referredRequestStatus: 2,
            name: userId?.name,
            email: userId?.email,
        };
        try {
            setIsLoading(true);

            let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const response = await editCompanybyId({
                body: encryptedBody,
                id: userId?.referredPeople?._id,
            }).unwrap();
            if (response?.statusCode === 200) {
                setIsLoading(false);
                setValue(1);
                dispatch(
                    setRequestCount({
                        agents: agentCount,
                        company: companyCount,
                        companyRequests: companyRequestCount,
                        propertyRequest: propertyRequestCount,
                        paymentRequestCount: paymentRequestCount ? paymentRequestCount - 1 : 0,
                        shortTermRequests: shortTermRequestCount
                    })
                );
                successToast("Request accepted successfully");
            }
        } catch (error: any) {
            setIsLoading(false);
            errorToast(error?.data?.message || "");
        }
    };

    const handleReject = async () => {
        const body = {
            referredRequestStatus: 3,
            affiliateRejectionReason: rejectionReason,
            name: selectedCompany?.name,
            email: selectedCompany?.email,
        };

        try {
            setIsLoading(true);

            let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const response = await editCompanybyId({
                body: encryptedBody,
                id: selectedCompany?.referredPeople?._id,
            }).unwrap();
            if (response?.statusCode === 200) {
                setIsLoading(false);
                setRejectionReason("");
                setValue(2);
                dispatch(
                    setRequestCount({
                        agents: agentCount,
                        company: companyCount,
                        companyRequests: companyRequestCount,
                        propertyRequest: propertyRequestCount,
                        paymentRequestCount: paymentRequestCount ? paymentRequestCount - 1 : 0,
                        shortTermRequests: shortTermRequestCount
                    })
                );
                successToast("Request rejected successfully");
            }
        } catch (error: any) {
            setIsLoading(false);
            errorToast(error?.data?.message || "");
        }
    };

    const label = { inputProps: { "aria-label": "Switch demo" } };


    useLayoutEffect(() => {
        if (state) {
            setValue(state);
        }
    }, []);

    useEffect(() => {
        getRequest();
    }, [value, page, limit, debouncedSearchTerm])



    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Payment Request</h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <Box className="cards_header_left">
                            <SearchBar2
                                value={searchTerm}
                                searchTerm={searchTerm}
                                onCross={() => setSearchTerm("")}
                                setDebouncedSearchTerm={setDebouncedSearchTerm}
                                onChange={(val: any) => {
                                    if (isValidInput(val.target.value)) {
                                        setSearchTerm(val.target.value);
                                    }
                                }}
                            />
                            <div className="control_group" style={{ width: "250px" }}>
                                <TextField
                                    // label="Select Date Range"
                                    variant="outlined"
                                    hiddenLabel
                                    className="text_field-range"
                                    fullWidth
                                    onClick={handleClick}
                                    value={getFormattedRange()}
                                    placeholder="Select Date Range"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: range?.from ? (
                                            <InputAdornment
                                                position="end"
                                                style={{ marginLeft: -50 }}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setRange({
                                                            from: undefined,
                                                            to: undefined,
                                                        });
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Popper
                                open={open1}
                                anchorEl={anchorEl}
                                placement="bottom-start"
                                disablePortal
                                style={{
                                    position: "relative",
                                    zIndex: 1,
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Paper
                                        elevation={3}
                                        style={{ position: "relative", zIndex: 1 }}
                                    >
                                        <div
                                            style={{
                                                padding: 16,
                                                borderRadius: 10,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <Chip
                                                    label="Last Week"
                                                    variant={
                                                        selectedChip === "lastWeek" ? "filled" : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastWeek")}
                                                />
                                                <Chip
                                                    label="Last Month"
                                                    variant={
                                                        selectedChip === "lastMonth" ? "filled" : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastMonth")}
                                                />
                                                <Chip
                                                    label="Last Year"
                                                    variant={
                                                        selectedChip === "lastYear" ? "filled" : "outlined"
                                                    }
                                                    onClick={() => handleChipClick("lastYear")}
                                                />
                                            </div>

                                            <DayPicker
                                                className="react-day-picker"
                                                mode="range"
                                                selected={range}
                                                onSelect={handleSelect}
                                                // numberOfMonths={10}
                                                month={month}
                                                onMonthChange={handleMonthChange}
                                            // styles={{
                                            //   months: { display: "flex", gap: "16px" },
                                            //   month: { margin: 0 },
                                            // }}
                                            />
                                        </div>
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                        </Box>

                    </Box>
                    <Box className="custom_tabs">
                        <Tabs
                            style={{ marginTop: "20px" }}
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                            className="custom_tabs_links"
                        >
                            <Tab
                                label={`Pending (${dataCount?.pendingRequests})`}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={`Approved (${dataCount?.aproovedRequests})`}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label={`Rejected (${dataCount?.rejectedRequests})`}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                        <CustomTabPanel value={value} index={0}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>Member Id</TableCell>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Subscription Cost</TableCell>
                                            <TableCell>Commission</TableCell>
                                            <TableCell>Request</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows1?.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={9} align="center">
                                                    No Request found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows1?.map((row: any, i: any) => (
                                                <TableRow key={row?._id}>
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>
                                                    <TableCell>{row?.referenceId || "-"}</TableCell>

                                                    <TableCell>
                                                        <figure

                                                            className="user_img"
                                                        >
                                                            <img
                                                                src={row?.image || "/static/images/user_placeholder.png"}
                                                                alt=""
                                                            />
                                                        </figure>
                                                    </TableCell>
                                                    <TableCell>{row?.name || "-"}</TableCell>
                                                    <TableCell>{row?.email || "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.referredPeople?.companyName + " (" + row?.referredPeople?.referenceId + ")" || "-"}</TableCell>
                                                    <TableCell align="center">{row?.referredSubscription?.price || "-"}</TableCell>
                                                    <TableCell align="center">
                                                        {row?.affiliateCommission ? row?.affiliateCommission + "%" : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            fullWidth
                                                            className="select_div reqst_Select"
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={receiver}
                                                            inputProps={{
                                                                "aria-label": "Without label",
                                                            }}
                                                            displayEmpty
                                                        // onChange={(value: any) => {
                                                        //     handleReceiverChange(value, row?._id);
                                                        // }}
                                                        >
                                                            <MenuItem value="1" disabled>
                                                                Pending
                                                            </MenuItem>

                                                            <MenuItem
                                                                value="2"
                                                                onClick={() => handleApprove(row)}
                                                            >
                                                                Approve
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="3"
                                                                onClick={() => {
                                                                    setSelectedCompany(row);
                                                                    setOpenReject(true);
                                                                }}
                                                            >
                                                                Reject
                                                            </MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows1?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    limit={limit}
                                    setLimit={setLimit}
                                    module={rows1}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                />
                            ) : (
                                ""
                            )}

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>Member Id</TableCell>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Subscription Cost</TableCell>
                                            <TableCell>Commission</TableCell>
                                            <TableCell>Request</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows1?.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={9} align="center">
                                                    No Request found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows1?.map((row: any, i: any) => (
                                                <TableRow key={row?._id}>
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>
                                                    <TableCell>{row?.referenceId || "-"}</TableCell>

                                                    <TableCell>
                                                        <figure

                                                            className="user_img"
                                                        >
                                                            <img
                                                                src={row?.image || "/static/images/user_placeholder.png"}
                                                                alt=""
                                                            />
                                                        </figure>
                                                    </TableCell>
                                                    <TableCell>{row?.name || "-"}</TableCell>
                                                    <TableCell>{row?.email || "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.referredPeople?.companyName + " (" + row?.referredPeople?.referenceId + ")" || "-"}</TableCell>

                                                    <TableCell align="center">{row?.referredSubscription?.price || "-"}</TableCell>
                                                    <TableCell align="center">
                                                        {row?.affiliateCommission ? row?.affiliateCommission + "%" : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        Approved
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows1?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    limit={limit}
                                    setLimit={setLimit}
                                    module={rows1}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                />
                            ) : (
                                ""
                            )}

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>Member Id</TableCell>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Subscription Cost</TableCell>
                                            <TableCell>Commission</TableCell>
                                            <TableCell>Reason</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows1?.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={9} align="center">
                                                    No Request found
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows1?.map((row: any, i: any) => (
                                                <TableRow key={row?._id}>
                                                    <TableCell align="center">
                                                        {(page - 1) * 10 + i + 1}
                                                    </TableCell>
                                                    <TableCell>{row?.referenceId || "-"}</TableCell>

                                                    <TableCell>
                                                        <figure

                                                            className="user_img"
                                                        >
                                                            <img
                                                                src={row?.image || "/static/images/user_placeholder.png"}
                                                                alt=""
                                                            />
                                                        </figure>
                                                    </TableCell>
                                                    <TableCell>{row?.name || "-"}</TableCell>
                                                    <TableCell>{row?.email || "-"}</TableCell>
                                                    <TableCell>
                                                        {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                                                    </TableCell>
                                                    <TableCell>{row?.referredPeople?.companyName + " (" + row?.referredPeople?.referenceId + ")" || "-"}</TableCell>

                                                    <TableCell align="center">{row?.referredSubscription?.price || "-"}</TableCell>
                                                    <TableCell align="center">
                                                        {row?.affiliateCommission ? row?.affiliateCommission + "%" : ""}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Click to see reason" >
                                                            <IconButton
                                                                onClick={() => { setMsg(row?.referredPeople?.affiliateRejectionReason); setOpen(true) }}
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {rows1?.length > 0 ? (
                                <Pagination
                                    setPage={setPage}
                                    limit={limit}
                                    setLimit={setLimit}
                                    module={rows1}
                                    page={page}
                                    onPageChange={onPageChange}
                                    totalPages={totalPages}
                                />
                            ) : (
                                ""
                            )}

                        </CustomTabPanel>
                    </Box>
                    <ReasonModal
                        open={openReject}
                        setOpen={setOpenReject}
                        handleDelete={handleReject}
                        reason={rejectionReason}
                        setReason={setRejectionReason}
                    />
                    <FeedbackDetails
                        open={open}
                        setOpen={setOpen}
                        details={msg}
                        msg="Rejection Reason"
                    />
                </Card>
            </div>
        </div>
    );
};

export default ManagePaymentRequest;
