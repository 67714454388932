import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";

import {
    Card,

    Box,

    Typography,
    Tabs,
    Tab,
    NativeSelect,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { AddTokenModal } from "../../components";
import { useLazyGetAllListingQuery } from "../../services/listing";
import { errorToast } from "../../helpers";
import Loader from "../../helpers/constants/Loader";
import AddIcon from '@mui/icons-material/Add';



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}



const ManageListing = () => {



    const [value, setValue] = useState<number>(1)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [id, setId] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [type, setType] = useState<string>('');
    const [getAllListing] = useLazyGetAllListingQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [listing, setListing] = useState<any>();

    const fetchListing = async () => {
        try {
            setIsLoading(true)
            const response = await getAllListing({}).unwrap();
            setIsLoading(false)
            if (response?.statusCode === 200) {
                setListing(response?.data?.listingDetails)
            }
        } catch (error: any) {
            setIsLoading(false)
            errorToast(error?.data?.message)
        }
    }

    useEffect(() => {
        fetchListing();
    }, [])



    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Listing</h1>
                </div>
                <Card className="cards">

                    <Box className="custom_tabs">
                        {/* <Box className="cards_header_right" sx={{justifyContent:"space-between"}}> */}
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                            className="custom_tabs_links"
                        >
                           
                            <Tab label="Featured" {...a11yProps(0)} />
                            <Tab label="Premium" {...a11yProps(1)} />
                        </Tabs>

                        {/* <Button
                                className="btn btn_primary"
                            >
                                <AddIcon />
                                Add Listing
                            </Button>
                        </Box> */}
                      
                        <CustomTabPanel value={value} index={0}
                        >
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>Duration</TableCell>
                                            <TableCell>Tokens</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {listing?.length ? (
                                            listing?.filter((item: any) => item?.listingType === 2)?.map((item: any, index: number) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{item?.validity} days</TableCell>
                                                        <TableCell>{item?.token} </TableCell>
                                                        <TableCell>
                                                            <Box className="table_actions">
                                                                <IconButton
                                                                    onClick={() => { setId(item?._id); setOpen(true); setType("2") }}
                                                                >
                                                                    <ModeEditIcon />
                                                                </IconButton>

                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })

                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    No Listing found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}
                        >
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell>Duration</TableCell>
                                            <TableCell>Tokens</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {listing?.length ? (
                                            listing?.filter((item: any) => item?.listingType === 3)?.map((item: any, index: number) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{item?.validity} days</TableCell>
                                                        <TableCell>{item?.token} </TableCell>
                                                        <TableCell>
                                                            <Box className="table_actions">
                                                                <IconButton
                                                                    onClick={() => { setId(item?._id); setOpen(true); setType("3") }}
                                                                >
                                                                    <ModeEditIcon />
                                                                </IconButton>

                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })

                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    No Listing found
                                                </TableCell>
                                            </TableRow>
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </CustomTabPanel>
                    </Box>
                </Card>
            </div>

            {/* <AddTokenModal
                open={open}
                setOpen={setOpen}
                listingType={type}
                id={id}
                getListing={fetchListing}
            /> */}

        </div>
    );
};

export default ManageListing;


