import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  useDeleteByIdNotificationMutation,
  useLazyRcvdAllNotificatiosQuery,
} from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";

import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  getEnquriesCount,
  getFeedbackCount,
  getNewListingActionTracker,
  getNewPropertyListing,
  getNewPropertyReports,
  getNewUsers,
  setNotificationCount,
} from "../../reducers/generalSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const RecievedNotifications = () => {
  const dispatch = useAppDispatch();
  const navigate=useNavigate()
  const [getAllNotifications, { isLoading }] =
    useLazyRcvdAllNotificatiosQuery();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteNotificationById] = useDeleteByIdNotificationMutation();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [rows, setRows] = useState<any>([]);
  const newUsers = useAppSelector(getNewUsers);
  const newPropertyReports = useAppSelector(getNewPropertyReports);
  const newPropertyListing = useAppSelector(getNewPropertyListing);
  const newListingActionTracker = useAppSelector(getNewListingActionTracker);
  const newEnquries = useAppSelector(getEnquriesCount)
  const newFeedback = useAppSelector(getFeedbackCount)
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  const fetchData = async (page: number, search: string) => {
    try {
      const response = await getAllNotifications({
        page: page,
        search: search,
        limit: limit,
      }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data[0]?.data || []);
        setTotalCount(response?.data[0]?.total);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message);
    }
  };
  const handleNotificationClick = (item: any) => {
    if (item?.pushType === 8) {
      navigate("/manage-users");
    } else if (item?.pushType === 9) {
      navigate("/manage-agents");
    } else if (item?.pushType === 10) {
      navigate("/manage-company");
    } else if (item?.pushType === 28) {
      navigate("/manage-company-requests");
    } else if (item?.pushType === 24 || item?.pushType === 25) {
      navigate("/manage-property-requests");
    } else if (item?.pushType === 30) {
      navigate("/manage-property-reports");
    } else if (item?.pushType === 31) {
      navigate("/listing-action-tracker");
    } else if (item?.pushType === 7) {
      navigate("/property-listing");
    } else if (item?.pushType === 32) {
      navigate("/manage-enqueries");
    } else if (item?.pushType === 33) {
      navigate("/manage-feedbacks");
    } else {
      navigate("/recieved-notifications");
    }
  };
  const handleDeleteRcvdNotification = async (userId: any) => {
    try {
      const response = await deleteNotificationById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Notification deleted successfully");
        fetchData(page, debouncedSearchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm, limit]);

  useEffect(() => {
    dispatch(
      setNotificationCount({
        newNotificationCount: 0,
        newUsers: newUsers,
        newPropertyListing: newPropertyListing,
        newPropertyReports: newPropertyReports,
        newListingActionTracker: newListingActionTracker,
        newEnquriesCount: newEnquries,
        newFeedbackCount: newFeedback,
      })
    );
  }, []);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Received Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No notification received
                    </TableCell>
                  </TableRow>
                ) : (
                  rows?.map((row: any, i: any) => (
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell align="center">
                        {row?.createdAt
                          ? moment(row?.createdAt).format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>

                      <TableCell>{row?.name || "-"}</TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>{row?.title || "-"}</TableCell>
                      <TableCell>{row?.message || "-"}</TableCell>
                      <TableCell>
                        <Box className="table_actions">
                        <IconButton
                            onClick={() => handleNotificationClick(row)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                         
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          limit={limit}
          setLimit={setLimit}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteRcvdNotification(selectedId)}
        name="Notification"
      />
    </div>
  );
};

export default RecievedNotifications;
