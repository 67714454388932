import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useAddSettingsMutation,
  useGetAllSettingsQuery,
  useLazyGetAllSettingsQuery,
} from "../../services/settings";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { isNumberPrice } from "../../utils/validation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [addSettings] = useAddSettingsMutation();
  const [getSettings] = useLazyGetAllSettingsQuery();

  const validationSchema = Yup.object().shape({
    tokenPrice: Yup.string()
      .required("This field is required"),
    reelType: Yup.string()
      .required("Please select an option"),
    // .typeError("This value must be a number"),
    dubaiDepartmentFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    abuDhabiDepartmentFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    otherDepartmentFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    agencyFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    agencyFeeVat: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    registrationTrustFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    registrationTrustAboveFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    registrationTrustBelowFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    conveyancerFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
  });
  const validationSchemaMortgage = Yup.object().shape({
    tokenPrice: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    downPayment: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    dubaiDepartmentFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    abuDhabiDepartmentFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    otherDepartmentFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    registrationTrustFee: Yup.number()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    mortageRegistrationFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    agencyFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    agencyFeeVat: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    bankArrangementFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    mortageVoluationFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
  });

  const validationSchemaRent = Yup.object().shape({
    vat: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    agencyFee: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    // securityUnfurnished: Yup.number().required("This field is required").typeError("This value must be a number"),
    securityFurnished: Yup.number()
      .required("This field is required")
      .typeError("This value must be a number"),
    dewaFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    adminFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
    ejariFee: Yup.string()
      .required("This field is required"),
    // .typeError("This value must be a number"),
  });

  const formik = useFormik({
    initialValues: {
      tokenPrice: "",
      reelType: "",
      dubaiDepartmentFee: "",
      abuDhabiDepartmentFee: "",
      otherDepartmentFee: "",
      agencyFee: "",
      agencyFeeVat: "",
      registrationTrustFee: "",
      conveyancerFee: "",
      registrationTrustAboveFee: "",
      registrationTrustBelowFee: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const body = {
        tokenPrice: Number(String(values.tokenPrice).replace(/,/g, '')),
        cashUpfront: {
          dubaiDepartmentFee: Number(values.dubaiDepartmentFee),
          abuDhabiDepartmentFee: Number(values.abuDhabiDepartmentFee),
          otherDepartmentFee: Number(values.otherDepartmentFee),
          agencyFee: Number(values.agencyFee),
          vat: Number(values.agencyFeeVat),
          registrationTrustAmount: Number(String(values.registrationTrustFee).replace(/,/g, '')),
          registrationTrustAboveFee: Number(String(values.registrationTrustAboveFee).replace(/,/g, '')),
          registrationTrustBelowFee: Number(String(values.registrationTrustBelowFee).replace(/,/g, '')),
          conveyancerFee: Number(String(values.conveyancerFee).replace(/,/g, '')),
          
        },
        reelType:Number(values?.reelType)
      };
      formikMortgage.setFieldValue("tokenPrice", values?.tokenPrice);

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        let response = await addSettings(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Settings Updated Successfully");
        } else {
          errorToast(response?.message);
        }
      } catch (e: any) {
        console.error("API call failed:", e);
        errorToast(e?.message);
      }
    },
  });


  const formikMortgage = useFormik({
    initialValues: {
      tokenPrice: "",
      downPayment: "",
      dubaiDepartmentFee: "",
      abuDhabiDepartmentFee: "",
      otherDepartmentFee: "",
      registrationTrustFee: "",
      mortageRegistrationFee: "",
      agencyFee: "",
      agencyFeeVat: "",
      bankArrangementFee: "",
      mortageVoluationFee: "",
      registrationTrustAboveFee: "",
      registrationTrustBelowFee: "",
    },
    validationSchema: validationSchemaMortgage,
    onSubmit: async (values) => {
      const body = {
        tokenPrice: Number(values?.tokenPrice),
        mortageUpfront: {
          downPayment: Number(values.downPayment),
          dubaiDepartmentFee: Number(values.dubaiDepartmentFee),
          abuDhabiDepartmentFee: Number(values.abuDhabiDepartmentFee),
          otherDepartmentFee: Number(values.otherDepartmentFee),
          agencyFee: Number(values.agencyFee),
          vat: Number(values.agencyFeeVat),
          registrationTrustAmount: Number(values.registrationTrustFee),
          mortageRegistrationFee: Number(values.mortageRegistrationFee),
          registrationTrustAboveFee: Number(values.registrationTrustAboveFee),
          registrationTrustBelowFee: Number(values.registrationTrustBelowFee),
          bankArrangementFee: Number(values.bankArrangementFee),
          mortageVoluationFee: Number(values.mortageVoluationFee),
        },
      };
      formik.setFieldValue("tokenPrice", values?.tokenPrice);

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        let response = await addSettings(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Settings Updated Successfully");
        } else {
          errorToast(response?.message);
        }
      } catch (e: any) {
        console.error("API call failed:", e);
        errorToast(e?.message);
      }
    },
  });

  const formikRent = useFormik({
    initialValues: {
      tokenPrice: "",
      vat: "",
      agencyFee: "",
      securityUnfurnished: "",
      securityFurnished: "",
      dewaFee: "",
      adminFee: "",
      ejariFee: "",
      reelType: ""
    },
    validationSchema: validationSchemaRent,
    onSubmit: async (values) => {
      const body = {
        tokenPrice: Number(String(values.tokenPrice).replace(/,/g, '')),
        rentCashUpfront: {
          vat: Number(values.vat),
          agencyFee: Number(values.agencyFee),
          // securityUnfurnished: Number(values.securityUnfurnished),
          securityUnfurnished: 0,
          securityFurnished: Number(values.securityFurnished),
          dewaFee: Number(String(values.dewaFee).replace(/,/g, '')),
          adminFee: Number(String(values.adminFee).replace(/,/g, '')),
          ejariFee: Number(String(values.ejariFee).replace(/,/g, '')),
        },
        reelType:Number(values?.reelType)
      };
      formik.setFieldValue("tokenPrice", values?.tokenPrice);

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        let response = await addSettings(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Settings Updated Successfully");
        } else {
          errorToast(response?.message);
        }
      } catch (e: any) {
        console.error("API call failed:", e);
        errorToast(e?.message);
      }
    },
  });

  const {
    data,
    isError,
    isSuccess,
    isLoading: load,
  } = useGetAllSettingsQuery({});

  function formatNumberWithCommas(value: string) {
    const [integer, decimal] = value.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  }


  useEffect(() => {
    if (isSuccess && data) {
      const initialData = data?.data;
      if (initialData) {
        formik.setValues({
          tokenPrice: formatNumberWithCommas(String(initialData?.tokenPrice)),
          reelType: initialData?.reelType ? String(initialData?.reelType) : "",
          dubaiDepartmentFee: initialData?.cashUpfront?.dubaiDepartmentFee,
          abuDhabiDepartmentFee:
            initialData?.cashUpfront?.abuDhabiDepartmentFee,
          otherDepartmentFee: initialData?.cashUpfront?.otherDepartmentFee,
          agencyFee: initialData?.cashUpfront?.agencyFee,
          agencyFeeVat: initialData?.cashUpfront?.vat,
          registrationTrustFee:
            formatNumberWithCommas(String(initialData?.cashUpfront?.registrationTrustAmount)),
          registrationTrustAboveFee:
            formatNumberWithCommas(String(initialData?.cashUpfront?.registrationTrustAboveFee)),
          registrationTrustBelowFee:
            formatNumberWithCommas(String(initialData?.cashUpfront?.registrationTrustBelowFee)),
          conveyancerFee: formatNumberWithCommas(String(initialData?.cashUpfront?.conveyancerFee)),
        });
        formikMortgage.setValues({
          tokenPrice: formatNumberWithCommas(String(initialData?.tokenPrice)),
          downPayment: initialData?.mortageUpfront?.downPayment,
          dubaiDepartmentFee: initialData?.mortageUpfront?.dubaiDepartmentFee,
          abuDhabiDepartmentFee:
            initialData?.mortageUpfront?.abuDhabiDepartmentFee,
          otherDepartmentFee: initialData?.mortageUpfront?.otherDepartmentFee,
          registrationTrustFee:
            initialData?.mortageUpfront?.registrationTrustAmount,
          mortageRegistrationFee:
            initialData?.mortageUpfront?.mortageRegistrationFee,
          agencyFee: initialData?.mortageUpfront?.agencyFee,
          agencyFeeVat: initialData?.mortageUpfront?.vat,
          bankArrangementFee: initialData?.mortageUpfront?.bankArrangementFee,
          mortageVoluationFee: initialData?.mortageUpfront?.mortageVoluationFee,
          registrationTrustAboveFee:
            initialData?.mortageUpfront?.registrationTrustAboveFee,
          registrationTrustBelowFee:
            initialData?.mortageUpfront?.registrationTrustBelowFee,
        });
        formikRent.setValues({
          tokenPrice: formatNumberWithCommas(String(initialData?.tokenPrice)),
          reelType: initialData?.reelType ? String(initialData?.reelType) : "",

          vat: initialData?.rentCashUpfront?.vat,
          agencyFee: initialData?.rentCashUpfront?.agencyFee,
          securityUnfurnished:
            initialData?.rentCashUpfront?.securityUnfurnished,
          securityFurnished: initialData?.rentCashUpfront?.securityFurnished,
          dewaFee: formatNumberWithCommas(String(initialData?.rentCashUpfront?.dewaFee)),
          adminFee: formatNumberWithCommas(String(initialData?.rentCashUpfront?.adminFee)),
          ejariFee: formatNumberWithCommas(String(initialData?.rentCashUpfront?.ejariFee)),
        });

        // formik.setFieldValue("beds", initialData?.beds || "");
        // formik.setFieldValue("baths", initialData?.baths ||"");
      }
    }
  }, [isSuccess, data]);

  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="custom_label">
                  Per token price
                </Typography>
                {!value ? (
                  <TextField
                    hiddenLabel
                    className="text_field"
                    type="text"
                    name="tokenPrice"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 8 }}
                    placeholder="Enter Token Price"
                    onChange={(val) => {
                      let { value } = val.target;
                      const numericValue = value.replace(/,/g, '');
                      if (
                        isNumberPrice(numericValue) &&
                        numericValue.length <= 12
                      ) {
                        const formattedValue =
                          formatNumberWithCommas(numericValue);
                        formik.setFieldValue('tokenPrice', formattedValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.tokenPrice}
                    error={
                      formik.touched?.tokenPrice &&
                      Boolean(formik.errors.tokenPrice)
                    }
                    helperText={
                      formik.touched.tokenPrice && formik.errors.tokenPrice
                    }
                  />
                ) : value == 1 ? (
                  <TextField
                    hiddenLabel
                    className="text_field"
                    type="text"
                    name="tokenPrice"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 8 }}
                    placeholder="Enter Token Price"
                    onChange={formikMortgage?.handleChange}
                    onBlur={formikMortgage.handleBlur}
                    value={formikMortgage.values?.tokenPrice}
                    error={
                      formikMortgage.touched?.tokenPrice &&
                      Boolean(formikMortgage.errors.tokenPrice)
                    }
                    helperText={
                      formikMortgage.touched.tokenPrice &&
                      formikMortgage.errors.tokenPrice
                    }
                  />
                ) : (
                  <TextField
                    hiddenLabel
                    className="text_field"
                    type="text"
                    name="tokenPrice"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 8 }}
                    placeholder="Enter Token Price"
                    onChange={formikRent?.handleChange}
                    onBlur={formikRent.handleBlur}
                    value={formikRent.values?.tokenPrice}
                    error={
                      formikRent.touched?.tokenPrice &&
                      Boolean(formikRent.errors.tokenPrice)
                    }
                    helperText={
                      formikRent.touched.tokenPrice &&
                      formikRent.errors.tokenPrice
                    }
                  />
                )}
              </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography className="custom_label">Select Who can upload reels </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      value={
                        value===0
                          ? formik.values?.reelType:
                           formikRent.values?.reelType
                      }
                      onChange={(e) =>
                        value === 0
                          ? formik.setFieldValue('reelType', e.target.value) : 
                            formikRent.setFieldValue('reelType', e.target.value) 
                      }
                      name="reelType"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="1">Only Agents</MenuItem>
                      <MenuItem value="2">Only Companies</MenuItem>
                      <MenuItem value="3">All</MenuItem>
                      <MenuItem value="4">No one</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
            </Grid>
            <Box className="custom_tabs" marginTop={3}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Buy" {...a11yProps(0)} />
                {/* <Tab label="Mortage" {...a11yProps(1)} /> */}
                <Tab label="Rent" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={value} index={0}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Dubai Land Department Fee (%)
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="dubaiDepartmentFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.dubaiDepartmentFee}
                        error={
                          formik.touched?.dubaiDepartmentFee &&
                          Boolean(formik.errors.dubaiDepartmentFee)
                        }
                        helperText={
                          formik.touched.dubaiDepartmentFee &&
                          formik.errors.dubaiDepartmentFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Abu Dhabi Land Department Fee (%)
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="abuDhabiDepartmentFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.abuDhabiDepartmentFee}
                        error={
                          formik.touched?.abuDhabiDepartmentFee &&
                          Boolean(formik.errors.abuDhabiDepartmentFee)
                        }
                        helperText={
                          formik.touched.abuDhabiDepartmentFee &&
                          formik.errors.abuDhabiDepartmentFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Other Land Department Fee (%)
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="otherDepartmentFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.otherDepartmentFee}
                        error={
                          formik.touched?.otherDepartmentFee &&
                          Boolean(formik.errors.otherDepartmentFee)
                        }
                        helperText={
                          formik.touched.otherDepartmentFee &&
                          formik.errors.otherDepartmentFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Real Estate Agency Fee (%)
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="agencyFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.agencyFee}
                        error={
                          formik.touched?.agencyFee &&
                          Boolean(formik.errors.agencyFee)
                        }
                        helperText={
                          formik.touched.agencyFee && formik.errors.agencyFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Vat (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="agencyFeeVat"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.agencyFeeVat}
                        error={
                          formik.touched?.agencyFeeVat &&
                          Boolean(formik.errors.agencyFeeVat)
                        }
                        helperText={
                          formik.touched.agencyFeeVat &&
                          formik.errors.agencyFeeVat
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Registration Trustee Fee{" "}
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="registrationTrustFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formik.setFieldValue('registrationTrustFee', formattedValue);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values?.registrationTrustFee}
                        error={
                          formik.touched?.registrationTrustFee &&
                          Boolean(formik.errors.registrationTrustFee)
                        }
                        helperText={
                          formik.touched.registrationTrustFee &&
                          formik.errors.registrationTrustFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Registration Trustee Above Fee{" "}
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="registrationTrustAboveFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formik.setFieldValue('registrationTrustAboveFee', formattedValue);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values?.registrationTrustAboveFee}
                        error={
                          formik.touched?.registrationTrustAboveFee &&
                          Boolean(formik.errors.registrationTrustAboveFee)
                        }
                        helperText={
                          formik.touched.registrationTrustAboveFee &&
                          formik.errors.registrationTrustAboveFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Registration Trustee Below Fee{" "}
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="registrationTrustBelowFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formik.setFieldValue('registrationTrustBelowFee', formattedValue);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values?.registrationTrustBelowFee}
                        error={
                          formik.touched?.registrationTrustBelowFee &&
                          Boolean(formik.errors.registrationTrustBelowFee)
                        }
                        helperText={
                          formik.touched.registrationTrustBelowFee &&
                          formik.errors.registrationTrustBelowFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Conveyancer Services Fee
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="conveyancerFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formik.setFieldValue('conveyancerFee', formattedValue);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values?.conveyancerFee}
                        error={
                          formik.touched?.conveyancerFee &&
                          Boolean(formik.errors.conveyancerFee)
                        }
                        helperText={
                          formik.touched.conveyancerFee &&
                          formik.errors.conveyancerFee
                        }
                      />
                    </Grid>
                  </Grid>

                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </CustomTabPanel>

              {/* <CustomTabPanel value={value} index={1}
              >
                <form onSubmit={formikMortgage.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Down Payment (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="downPayment"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.downPayment}
                        error={formikMortgage.touched?.downPayment && Boolean(formikMortgage.errors.downPayment)}
                        helperText={formikMortgage.touched.downPayment && formikMortgage.errors.downPayment}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Dubai Land Department Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="dubaiDepartmentFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.dubaiDepartmentFee}
                        error={formikMortgage.touched?.dubaiDepartmentFee && Boolean(formikMortgage.errors.dubaiDepartmentFee)}
                        helperText={formikMortgage.touched.dubaiDepartmentFee && formikMortgage.errors.dubaiDepartmentFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Abu Dhabi Land Department Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="abuDhabiDepartmentFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.abuDhabiDepartmentFee}
                        error={formikMortgage.touched?.abuDhabiDepartmentFee && Boolean(formikMortgage.errors.abuDhabiDepartmentFee)}
                        helperText={formikMortgage.touched.abuDhabiDepartmentFee && formikMortgage.errors.abuDhabiDepartmentFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Other Land Department Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="otherDepartmentFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.otherDepartmentFee}
                        error={formikMortgage.touched?.otherDepartmentFee && Boolean(formikMortgage.errors.otherDepartmentFee)}
                        helperText={formikMortgage.touched.otherDepartmentFee && formikMortgage.errors.otherDepartmentFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Registration Trustee Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="registrationTrustFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.registrationTrustFee}
                        error={formikMortgage.touched?.registrationTrustFee && Boolean(formikMortgage.errors.registrationTrustFee)}
                        helperText={formikMortgage.touched.registrationTrustFee && formikMortgage.errors.registrationTrustFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Mortage Registration Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="mortageRegistrationFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.mortageRegistrationFee}
                        error={formikMortgage.touched?.mortageRegistrationFee && Boolean(formikMortgage.errors.mortageRegistrationFee)}
                        helperText={formikMortgage.touched.mortageRegistrationFee && formikMortgage.errors.mortageRegistrationFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Real Estate Agency Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="agencyFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.agencyFee}
                        error={formikMortgage.touched?.agencyFee && Boolean(formikMortgage.errors.agencyFee)}
                        helperText={formikMortgage.touched.agencyFee && formikMortgage.errors.agencyFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Real Estate Agency Fee VAT (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="agencyFeeVat"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.agencyFeeVat}
                        error={formikMortgage.touched?.agencyFeeVat && Boolean(formikMortgage.errors.agencyFeeVat)}
                        helperText={formikMortgage.touched.agencyFeeVat && formikMortgage.errors.agencyFeeVat}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Bank Arrangement Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="bankArrangementFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.bankArrangementFee}
                        error={formikMortgage.touched?.bankArrangementFee && Boolean(formikMortgage.errors.bankArrangementFee)}
                        helperText={formikMortgage.touched.bankArrangementFee && formikMortgage.errors.bankArrangementFee}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Mortage Valuation Fee (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="mortageVoluationFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikMortgage?.handleChange}
                        onBlur={formikMortgage.handleBlur}
                        value={formikMortgage.values?.mortageVoluationFee}
                        error={formikMortgage.touched?.mortageVoluationFee && Boolean(formikMortgage.errors.mortageVoluationFee)}
                        helperText={formikMortgage.touched.mortageVoluationFee && formikMortgage.errors.mortageVoluationFee}
                      />
                    </Grid>
                  </Grid>

                  <div className="form_btn">
                    <Button size="large" type="submit" className="btn btn_primary">
                      Save
                    </Button>
                  </div>

                </form>
              </CustomTabPanel> */}

              <CustomTabPanel value={value} index={1}>
                <form onSubmit={formikRent.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Vat (%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="vat"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikRent?.handleChange}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.vat}
                        error={
                          formikRent.touched?.vat &&
                          Boolean(formikRent.errors.vat)
                        }
                        helperText={
                          formikRent.touched.vat && formikRent.errors.vat
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Agency Fee (%)
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="agencyFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikRent?.handleChange}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.agencyFee}
                        error={
                          formikRent.touched?.agencyFee &&
                          Boolean(formikRent.errors.agencyFee)
                        }
                        helperText={
                          formikRent.touched.agencyFee &&
                          formikRent.errors.agencyFee
                        }
                      />
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Security Unfurnished(%)</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="securityUnfurnished"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikRent?.handleChange}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.securityUnfurnished}
                        error={formikRent.touched?.securityUnfurnished && Boolean(formikRent.errors.securityUnfurnished)}
                        helperText={formikRent.touched.securityUnfurnished && formikRent.errors.securityUnfurnished}
                      />
                    </Grid> */}
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Security Deposit (%)
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="securityFurnished"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        placeholder="Enter here"
                        onChange={formikRent?.handleChange}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.securityFurnished}
                        error={
                          formikRent.touched?.securityFurnished &&
                          Boolean(formikRent.errors.securityFurnished)
                        }
                        helperText={
                          formikRent.touched.securityFurnished &&
                          formikRent.errors.securityFurnished
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">Dewa Fee</Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="dewaFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formikRent.setFieldValue('dewaFee', formattedValue);
                          }
                        }}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.dewaFee}
                        error={
                          formikRent.touched?.dewaFee &&
                          Boolean(formikRent.errors.dewaFee)
                        }
                        helperText={
                          formikRent.touched.dewaFee &&
                          formikRent.errors.dewaFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Admin Fee
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="adminFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formikRent.setFieldValue('adminFee', formattedValue);
                          }
                        }}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.adminFee}
                        error={
                          formikRent.touched?.adminFee &&
                          Boolean(formikRent.errors.adminFee)
                        }
                        helperText={
                          formikRent.touched.adminFee &&
                          formikRent.errors.adminFee
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Typography className="custom_label">
                        Ejari Fee{" "}
                      </Typography>
                      <TextField
                        hiddenLabel
                        className="text_field"
                        type="text"
                        name="ejariFee"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Enter here"
                        onChange={(val) => {
                          let { value } = val.target;
                          const numericValue = value.replace(/,/g, '');
                          if (
                            isNumberPrice(numericValue) &&
                            numericValue.length <= 12
                          ) {
                            const formattedValue =
                              formatNumberWithCommas(numericValue);
                            formikRent.setFieldValue('ejariFee', formattedValue);
                          }
                        }}
                        onBlur={formikRent.handleBlur}
                        value={formikRent.values?.ejariFee}
                        error={
                          formikRent.touched?.ejariFee &&
                          Boolean(formikRent.errors.ejariFee)
                        }
                        helperText={
                          formikRent.touched.ejariFee &&
                          formikRent.errors.ejariFee
                        }
                      />
                    </Grid>
                  </Grid>

                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </CustomTabPanel>
            </Box>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Settings;
