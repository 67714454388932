import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLazyGetAllDetailsByIdQuery, useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { UserData } from "../../types/General";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetReportByIdQuery } from "../../services/propertyReports";
import MainContainer from "../../layout/MainContainer";

const PropertyReqDetails = () => {
    const { id } = useParams();
    const [ByIdDetails, setByIdDetails] = useState<any>();
    const [fetchApi, { isLoading }] = useLazyGetReportByIdQuery();
    const [open, setOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const handleClose = () => {
        setOpen(false)
    }
    const navigate = useNavigate();

    const fetchDataById = async () => {
        try {
            const response = await fetchApi({ id: id }).unwrap();
            if (response.statusCode === 200) {
                setByIdDetails(response?.data || {});
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setOpen(true);
    };

    useEffect(() => {
        if (id) { fetchDataById(); }
    }, [])

    return (
        <div>
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">View Property Report Details</h1>
                    <Button
                        className="btn btn_primary"
                        onClick={() => navigate("/manage-property-reports")}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <Loader isLoad={isLoading} />
                    <CardContent sx={{ p: 1 }}>
                        <Grid container spacing={2} className="view_box">
                            {ByIdDetails?.images?.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography component="h5">Images</Typography>
                                    <Grid container spacing={2}>
                                        {ByIdDetails.images.map((image: string, index: number) => (
                                            <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                                                <figure className="profile_img">
                                                    <img
                                                        style={{ borderRadius: 0, width: "100%" }}
                                                        src={image}
                                                        alt={`property-${index}`}
                                                    />
                                                    <div className="overlay">
                                                        <img
                                                            onClick={() => handleOpenModal(image)}
                                                            src='/static/images/visibility.png' alt='img'
                                                        />
                                                    </div>
                                                </figure>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={10} className="view_box_list">
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Property Name</Typography>
                                            <Typography component="p">{ByIdDetails?.propertyId?.name || "-"}</Typography>
                                        </Box>
                                    </Grid>


                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Reporter name</Typography>
                                            <Typography component="p">
                                                {ByIdDetails?.userId?.name || "-"}
                                            </Typography>

                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Reporter Email</Typography>
                                            <Typography component="p">
                                                {ByIdDetails?.userId?.email || "-"}
                                            </Typography>

                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Reporter Phone</Typography>
                                            <Typography component="p">
                                                {ByIdDetails?.userId?.dialCode + ByIdDetails?.userId?.phoneNo || "-"}
                                            </Typography>

                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Company Name</Typography>
                                            <Typography component="p">
                                                {ByIdDetails?.agentId?.parentAgentId?.companyName ? ByIdDetails?.agentId?.parentAgentId?.companyName + " (" + ByIdDetails?.agentId?.parentAgentId?.referenceId + ")" : "-"}
                                            </Typography>

                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Agent Name</Typography>
                                            <Typography component="p">
                                                {ByIdDetails?.agentId?.name ? ByIdDetails?.agentId?.name + " (" + ByIdDetails?.agentId?.referenceId + ")" : "-"}
                                            </Typography>

                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Report message</Typography>
                                            <Typography component="p">{ByIdDetails?.issue || "_"}</Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <ImageModal open={open} handleClose={handleClose} image={selectedImage} />
                </Card>
            </div>
        </div>
    );
};

export default PropertyReqDetails;
