import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";
import { usePostFeedbackApiMutation } from "../services/feedback";
import Loader from "../helpers/constants/Loader";
import { errorToast, successToast } from "../helpers";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { usePostEnqReplyMutation } from "../services/enquries";
import { useAppDispatch, useAppSelector } from "../hooks/store";
import { getAgentCount, getCompanyCount, getCompanyRequestCount, getEnquriesCount, getFeedbackCount, getPropertyRequestCount, setRequestCount } from "../reducers/generalSlice"

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;
    label: string;
};

const RevertQueryModal = ({ open, setOpen, id, label }: props) => {
    const style = generateResponsiveStyle();
    const [review, setReview] = useState<string>("")
    const [postRevert, { isLoading }] = usePostFeedbackApiMutation();
    const [postRevertOnEnq] = usePostEnqReplyMutation();
    const agentCount = useAppSelector(getAgentCount);
    const companyCount = useAppSelector(getCompanyCount);
    const companyRequestCount = useAppSelector(getCompanyRequestCount);
    const propertyRequestCount = useAppSelector(getPropertyRequestCount);
    const dispatch = useAppDispatch();

    const postReply = async () => {
        const body = {
            message: review
        }
        try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            let res;
            if (label === "Feedback") {
                res = await postRevert({
                    body: encryptedBody,
                    id: id
                }).unwrap();
            } else {
                res = await postRevertOnEnq({
                    body: encryptedBody,
                    id: id
                }).unwrap();
            }

            if (res?.statusCode === 200) {
               
                successToast(res?.data?.message || "Revert sent successfully")
                setOpen(false)
            }
        } catch (error: any) {

            errorToast(error?.data?.message)
            setOpen(false)
        }
    }

    return (
        <>
            <Loader isLoad={isLoading} />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal_title">
                        <Typography id="modal-modal-title" component="h2">Send a Revert message to User</Typography>
                        <CloseIcon className="cross_icn_logout" onClick={() => setOpen(false)} />
                    </div>

                    <div className="modal_body">
                        <div className="control_group">
                            <TextField
                                className="text_field"
                                hiddenLabel
                                placeholder="Type here.."
                                fullWidth
                                type="text"
                                variant="outlined"
                                multiline
                                minRows={3}
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                            />
                        </div>
                        <div className="form_btn">
                            <Button
                                disabled={review === ""}
                                className="btn btn_primary"
                                onClick={() => {
                                    postReply()
                                }}
                            >
                                Send
                            </Button>
                            <Button
                                className="btn btn_secondary"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default RevertQueryModal;
