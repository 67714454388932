import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  TextField,
  Popper,
  Paper,
  ClickAwayListener,
  Chip,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

import SearchBar2 from "../../components/SearchBar2";
import {
  useEditUserMutation,
  useDeleteByIduserMutation,
  useLazyGetAllUserQuery,
} from "../../services/users";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import { useLazyExportUserCsvQuery } from "../../services/dashboard";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getEnquriesCount,
  getFeedbackCount,
  getNewListingActionTracker,
  getNewPropertyListing,
  getNewPropertyReports,
  getNewUsers,
  getRecentNotificationCount,
  setNotificationCount,
} from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";

const ManageUsers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const newNotificationCount = useAppSelector(getRecentNotificationCount);
  const newPropertyReports = useAppSelector(getNewPropertyReports);
  const newPropertyListing = useAppSelector(getNewPropertyListing);
  const newListingActionTracker = useAppSelector(getNewListingActionTracker);
  const newEnquries = useAppSelector(getEnquriesCount)
  const newFeedback = useAppSelector(getFeedbackCount)

  const role = 4;
  const [getallusers] = useLazyGetAllUserQuery();
  const [rows, setRows] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [editUSERbyId] = useEditUserMutation();
  const [deleteUserById] = useDeleteByIduserMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [exportCsv] = useLazyExportUserCsvQuery();
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("");
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const handleExportCsv = async () => {
    setIsLoading(true);
    try {
      const res = await exportCsv({
        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        search: debouncedSearchTerm?.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setIsLoading(false);
        window.open(res?.data);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.message);
    }
  };
  const fetchData = async (role: number, page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await getallusers({
        page: page,
        search: search,
        limit: limit,
        role:4,
        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      if (response.statusCode === 200) {
        setIsLoading(false);
        setRows(response?.data?.user || []);
        setTotalCount(response?.data?.totalCount);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const handleDeleteUser = async (userId: any) => {
    setIsLoading(true);
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        successToast("User deleted successfully");
        fetchData(role, page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      setIsLoading(false);
      errorToast(error?.message || "");
    }
  };
  const changeUserStatus = async (userId: string, userStatus: boolean) => {
    setIsLoading(true);
    try {
      let body = {
        isBlocked: String(userStatus),
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({
        body: encryptedBody,
        id: userId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        successToast("Status updated successfully");
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.message);
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData(role, page, debouncedSearchTerm);
  }, [role, page, debouncedSearchTerm, limit, range]);

  useEffect(() => {
    dispatch(
      setNotificationCount({
        newNotificationCount: newNotificationCount,
        newUsers: 0,
        newPropertyListing: newPropertyListing,
        newPropertyReports: newPropertyReports,
        newListingActionTracker: newListingActionTracker,
        newEnquriesCount:newEnquries,
        newFeedbackCount: newFeedback,
      })
    );
  }, []);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard">
          <h2 className="mn_hdng">Manage Users</h2>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className="control_group">
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined,
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Popper
                open={open1}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper
                    elevation={3}
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <div
                      style={{
                        padding: 16,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Chip
                          label="Last Week"
                          variant={
                            selectedChip === "lastWeek" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastWeek")}
                        />
                        <Chip
                          label="Last Month"
                          variant={
                            selectedChip === "lastMonth" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastMonth")}
                        />
                        <Chip
                          label="Last Year"
                          variant={
                            selectedChip === "lastYear" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastYear")}
                        />
                      </div>

                      <DayPicker
                        className="react-day-picker"
                        mode="range"
                        selected={range}
                        onSelect={handleSelect}
                        // numberOfMonths={10}
                        month={month}
                        onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                      />
                    </div>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </Box>
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-users/usersform")}
              >
                <AddIcon /> Add User
              </Button>
              <Button
                className="btn btn_primary"
                onClick={handleExportCsv}
                disabled={rows?.length === 0 ? true : false}
              >
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <Box
            className="table_container"
            sx={{ height: "600px", overflowY: "auto" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>User Id</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Full name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Token</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No user found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows?.map((row: any, i: any) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.referenceId || "-"}</TableCell>
                      <TableCell>
                        <figure
                          onClick={() =>
                            navigate(`/manage-users/details/${row?._id}`)
                          }
                          className="user_img"
                        >
                          <img
                            src={
                              row?.image ||
                              "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row?.name || "-"}</TableCell>
                      <TableCell>
                        {row?.dialCode + row?.phoneNo || "-"}
                      </TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>{row?.token || "0"}</TableCell>
                      <TableCell>{row?.address || "-"}</TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={!row.isBlocked}
                          onChange={(e) => {
                            const isChecked = !e.target.checked;
                            changeUserStatus(row._id, isChecked);
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-users/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-users/usersform/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteUser(selectedId)}
        name="User"
      />
    </div>
  );
};

export default ManageUsers;
