import emptySplitApi from "../utils/rtk";
 
import { END_POINTS } from "../helpers";
 

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

 
export const emailApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
     
    getAllNotifyEmail: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string;limit?:number, range?:{ from: string | undefined;
      to: string | undefined;};}>({
      query: ({ page, search ,limit,range}) => {
        let url = `${END_POINTS.notifyList}?page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}`;
       
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor:3,
      providesTags:["GETEMAIL"]
    }),
    
    exportNotify: builder.query<CommonResponseType & { data: any }, {}>({
        query: ({ }) => {
          let url = END_POINTS.notifyExport;
          return {
            url: url,
            method: "GET",
          };
        },
      }),
 
 
  }),
});

export const {
    useLazyExportNotifyQuery,
    useGetAllNotifyEmailQuery
} = emailApi;
