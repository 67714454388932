import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    details: string;
    msg:string;
};

const FeedbackDetails = ({ open, setOpen, details,msg }: props) => {
    const style = generateResponsiveStyle();

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="modal_title">
                    <Typography id="modal-modal-title" component="h2">{msg}</Typography>
                    <CloseIcon className="cross_icn_logout" onClick={() => setOpen(false)} />
                </div>
                <div className="modal_body">
                    <div className="control_group">
                        <Typography className="custom_label">Message</Typography>
                        <div className="msg_box">
                            <p>{details || "No feedback message available"}</p>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default FeedbackDetails;
