import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

 
interface CategoryResponse extends CommonResponseType {
  propertyMode: string;
  data: any;
}

interface QueryParams {
  userId?: any;
  page?:any
}

export const propertyTypeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddpropertyType: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.propertyType,
        method: "POST",
        body,
      }),
      invalidatesTags:["GETPROPERTYTYPE"]
    }),
    getAllpropertyType: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string ;limit:number}>({
      query: ({ page, search ,limit}) => {
        let url = `${END_POINTS.propertyType}?page=${page}&search=${search}&limit=${limit}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor:3,
      providesTags: ["GETPROPERTYTYPE"],
    }),
    
   
    getSpecificpropertyTypeById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId, page }) => {
        let url = `${END_POINTS.propertyType}/${userId}`;
        if (page !== undefined) {
          url += `?page=${page}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),
    
 
  
    EditpropertyType: builder.mutation<
    CommonResponseType & { data: CommonBody },
    { id: string | undefined; body: CommonBody }
  >({
    query: ({ body, id }) => ({
      url: `${END_POINTS.propertyType}/${id}`,
      method: "PUT",
      body,
    }),
    invalidatesTags:["GETPROPERTYTYPE"]
  }),
  
    
      
    deleteByIdpropertyType:  builder.mutation<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.propertyType}/${userId}`,
          method: "DELETE",
        }),
        invalidatesTags:["GETPROPERTYTYPE"]
      }),
 
  }),
});

export const {
    usePostAddpropertyTypeMutation,
    useLazyGetAllpropertyTypeQuery,
    useGetAllpropertyTypeQuery,
    useLazyGetSpecificpropertyTypeByIdQuery,
    useEditpropertyTypeMutation,
    useDeleteByIdpropertyTypeMutation
} = propertyTypeApi;
