import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";

export interface RequestCount {
  company: number | undefined;
  agents: number | undefined;
  companyRequests: number | undefined;
  propertyRequest: number | undefined;
  newNotificationCount: number | undefined;
  newUsers: number | undefined;
  newPropertyReports: number | undefined;
  newPropertyListing: number | undefined;
  newListingActionTracker: number | undefined;
  newEnquriesCount: number | undefined;
  newFeedbackCount: number | undefined;
  paymentRequestCount: number | undefined;
  shortTermRequests: number | undefined;
}

const initialState: RequestCount = {
  company: 0,
  agents: 0,
  companyRequests: 0,
  propertyRequest: 0,
  newNotificationCount: 0,
  newUsers: 0,
  newPropertyReports: 0,
  newPropertyListing: 0,
  newListingActionTracker: 0,
  newEnquriesCount: 0,
  newFeedbackCount: 0,
  paymentRequestCount: 0,
  shortTermRequests: 0
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setRequestCount: (
      state,
      action: PayloadAction<
        Pick<
          RequestCount,
          "company" | "agents" | "companyRequests" | "propertyRequest" | "paymentRequestCount" | "shortTermRequests"
        >
      >
    ) => {
      state.company = action.payload.company;
      state.agents = action.payload.agents;
      state.companyRequests = action.payload.companyRequests;
      state.propertyRequest = action.payload.propertyRequest;
      state.paymentRequestCount = action.payload.paymentRequestCount;
      state.shortTermRequests = action.payload.shortTermRequests;


    },
    setNotificationCount: (
      state,
      action: PayloadAction<
        Pick<
          RequestCount,
          | "newNotificationCount"
          | "newUsers"
          | "newPropertyReports"
          | "newListingActionTracker"
          | "newPropertyListing"
          | "newEnquriesCount"
          | "newFeedbackCount"

        >
      >
    ) => {
      state.newNotificationCount = action.payload.newNotificationCount;
      state.newUsers = action.payload.newUsers;
      state.newPropertyReports = action.payload.newPropertyReports;
      state.newListingActionTracker = action.payload.newListingActionTracker;
      state.newPropertyListing = action.payload.newPropertyListing;
      state.newEnquriesCount = action.payload.newEnquriesCount;
      state.newFeedbackCount = action.payload.newFeedbackCount;

    },
  },
});
export const { setRequestCount, setNotificationCount } = generalSlice.actions;
export const getCompanyCount = (state: RootState) => state.general.company;
export const getAgentCount = (state: RootState) => state.general.agents;
export const getCompanyRequestCount = (state: RootState) =>
  state.general.companyRequests;
export const getShortTermRequestCount = (state: RootState) =>
  state.general.shortTermRequests;
export const getPropertyRequestCount = (state: RootState) =>
  state.general.propertyRequest;
export const getPaymentRequestCount = (state: RootState) =>
  state.general.paymentRequestCount;
export const getRecentNotificationCount = (state: RootState) =>
  state.general.newNotificationCount;
export const getNewUsers = (state: RootState) => state.general.newUsers;
export const getNewPropertyReports = (state: RootState) =>
  state.general.newPropertyReports;
export const getNewListingActionTracker = (state: RootState) =>
  state.general.newListingActionTracker;
export const getNewPropertyListing = (state: RootState) =>
  state.general.newPropertyListing;
export const getEnquriesCount = (state: RootState) =>
  state.general.newEnquriesCount;
export const getFeedbackCount = (state: RootState) =>
  state.general.newFeedbackCount;


export default generalSlice.reducer;
