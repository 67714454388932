import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAdvertisement: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.ads,
        method: "POST",
        body,
      }),
      invalidatesTags:["GETADS"]
    }),
    getAllAds: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit?: number }>({
      query: ({ page, search, limit }) => {
   
        
        let url = `${END_POINTS.ads}?page=${page}&search=${search}&limit=${limit}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor:3,
      providesTags:["GETADS"]
    }),



    getSpecificAdById: builder.query<CommonResponseType & { data: any }, { userId: any }>
      ({
        query: ({ userId }) => ({
          url: `${END_POINTS.ads}/${userId}`,
          method: "GET"
        })
      }),


    EditAds: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.ads}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags:["GETADS"]
    }),



    deleteByIdAd: builder.mutation<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.ads}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags:["GETADS"]
    }),

  }),
});

export const {
  usePostAdvertisementMutation,
  useGetAllAdsQuery,
  useLazyGetSpecificAdByIdQuery,
  useEditAdsMutation,
  useDeleteByIdAdMutation,

} = authApi;
