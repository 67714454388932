import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";


type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>
  assignToken: () => void;
};

const AddTokenModal = ({
  open,
  setOpen,
  token,
  setToken,
  assignToken
}: props) => {
  const style = generateResponsiveStyle();


  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal_title">
          <Typography id="modal-modal-title" component="h2">
            Send Tokens
          </Typography>
          <CloseIcon
            className="cross_icn_logout"
            onClick={() => {
              setOpen(false);
              setToken("")
            }}
          />
        </div>
        <div className="modal_body">
          <div className="control_group">
            <TextField
              hiddenLabel
              type="text"
              name="title"
              inputProps={{ maxLength: 10 }}
              variant="outlined"
              fullWidth
              value={token}
              placeholder="Enter tokens"
              className="text_field"
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^\d*\.?\d*$/;
                if (regex.test(value)) {
                  setToken(value);
                }
              }}
            />
          </div>

          <div className="form_btn">
            <Button
              className="btn btn_primary"
              onClick={()=>assignToken()}
              disabled={token === "" ? true : false}
            >
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddTokenModal;
