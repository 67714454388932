import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";
import moment from "moment";

const CompanyByIdDetails = () => {
  const location = useLocation();
  const { state } = location;
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>("");

  const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id, detailsType: 1 }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <Card className="cards">
        <CardContent sx={{ p: 1 }}>
          <h2 className="card_title">Personal Info</h2>
          <Grid container spacing={2} className="view_box">
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <figure className="profile_img">
                <img
                  src={
                    ByIdDetails?.image
                      ? ByIdDetails?.image
                      : `/static/images/picture.png`
                  }
                  alt="Profile"
                />
                <div className="overlay">
                  <img
                    onClick={() =>
                      handleOpenModal(
                        ByIdDetails?.image
                          ? ByIdDetails?.image
                          : "/static/images/picture.png"
                      )
                    }
                    src="/static/images/visibility.png"
                    alt="img"
                  />
                </div>
              </figure>
            </Grid>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                {/* Personal info fields */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Company Id</Typography>
                    <Typography component="p">
                      {ByIdDetails?.referenceId || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Company Name</Typography>
                    <Typography component="p">
                      {ByIdDetails?.companyName || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Full name</Typography>
                    <Typography component="p">
                      {ByIdDetails?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Email</Typography>
                    <Typography component="p">
                      {ByIdDetails?.email || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Phone Number</Typography>
                    <Typography component="p">
                      {ByIdDetails?.dialCode + "-" + ByIdDetails?.phoneNo ||
                        "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Area</Typography>
                    <Typography component="p">
                      {ByIdDetails?.area ||
                        "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Office Number</Typography>
                    <Typography component="p">
                      {ByIdDetails?.officeNumber ||
                        "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Building Name</Typography>
                    <Typography component="p">
                      {ByIdDetails?.buildingName ||
                        "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Emirates Id</Typography>
                    <Typography component="p">
                      {ByIdDetails?.emiratesId || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">ORN</Typography>
                    <Typography component="p">
                      {ByIdDetails?.ORN || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total active properties{" "}
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.activeProperties || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total inactive properties{" "}
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.inactiveProperties || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total Sold properties{" "}
                    </Typography>
                    <Typography component="p">{ByIdDetails?.TotalSoldProperties ||"0"}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total Rental properties{" "}
                    </Typography>
                    <Typography component="p">{ByIdDetails?.TotalRentalProperties ||"0"}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total number of claimed transactions{" "}
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.claimedTransactions || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Number of Titen agents{" "}
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.titenAgents || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Number of Normal agents{" "}
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.totalAgents || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Monthly subscription amount{" "}
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.currentSubscriptionPrice || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Current Subscription</Typography>
                    <Typography component="p">
                      {ByIdDetails?.currentSubscription || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Subscription Expiry Date
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.subscriptionExpiryDate
                        ? moment(ByIdDetails?.subscriptionExpiryDate).format(
                          "DD-MM-YYYY"
                        )
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Available Tokens</Typography>
                    <Typography component="p">
                      {ByIdDetails?.token || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Account Status</Typography>
                    <Typography component="p">
                      {ByIdDetails?.isBlocked === false ? "Active" : "Blocked"}
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Country</Typography>
                    <Typography component="p">
                      {ByIdDetails?.country || "-"}
                    </Typography>
                  </Box>
                </Grid> */}
                {ByIdDetails?.address ? (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Address</Typography>
                      <Typography component="p">
                        {ByIdDetails?.address || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                ) : ("")}

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">City</Typography>
                    <Typography component="p">
                      {ByIdDetails?.cityName || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Country</Typography>
                    <Typography component="p">
                      {ByIdDetails?.countryName || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Complete Address</Typography>
                    <Typography component="p">
                      {ByIdDetails?.companyLocation || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Documents section */}
                {/* <Grid item xs={12}>
                  <Box>
                    <Typography component="h5">Documents</Typography>
                    <Box className="docs_div">
                      <figure>
                        <img
                          src={ByIdDetails?.documents?.frontImage || "/static/images/adhar.jpeg"}
                          alt="Front Document"
                          // onClick={() => handleOpenModal(ByIdDetails?.documents?.frontImage || "/static/images/adhar.jpeg")}
                          style={{ cursor: "pointer", maxWidth: "300px" }}
                        />
                        <div className="overlay">
                          <img
                            onClick={() => handleOpenModal(ByIdDetails?.documents?.frontImage ? ByIdDetails?.documents?.frontImage : "/static/images/adhar.jpeg")}
                            src='/static/images/visibility.png' alt='img'
                          />
                        </div>
                      </figure>
                      <figure>
                        <img
                          src={ByIdDetails?.documents?.backImage || "/static/images/adhar.jpeg"}
                          alt="Back Document"
                          // onClick={() => handleOpenModal(ByIdDetails?.documents?.backImage || "/static/images/adhar.jpeg")}
                          style={{ cursor: "pointer", maxWidth: "300px" }}
                        />
                        <div className="overlay">
                          <img
                            onClick={() => handleOpenModal(ByIdDetails?.documents?.backImage ? ByIdDetails?.documents?.backImage : "/static/images/adhar.jpeg")}
                            src='/static/images/visibility.png' alt='img'
                          />
                        </div>
                      </figure>
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <ImageModal
          open={openModal}
          handleClose={handleCloseModal}
          image={selectedImage}
        />
      </Card>

      {/* <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            position: "relative",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "80%",
            overflow: "hidden",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "red"
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal> */}
    </>
  );
};

export default CompanyByIdDetails;
