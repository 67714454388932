import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SearchBar from "../../components/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import { useEditAdsMutation, useDeleteByIdAdMutation, useGetAllAdsQuery, useLazyGetSpecificAdByIdQuery } from "../../services/ads";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isNumber, isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useAddSettingsMutation, useLazyGetAllSettingsQuery } from "../../services/settings";
import moment from "moment";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const ManageAds = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [rows1, setRows] = useState<any>([]);
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [editUSERbyId] = useEditAdsMutation();
  const [shortPrice, setShortPrice] = useState<string>();
  const [longPrice, setLongPrice] = useState<string>();
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const today = new Date();
  const [addSettings] = useAddSettingsMutation();
  const [getSettings] = useLazyGetAllSettingsQuery();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [value, setValue] = useState<number>(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [deleteAd] = useDeleteByIdAdMutation();
  // const fetchAllAddsApi = async () => {
  //   console.log(limit, "limit in fun");

  //   try {
  //     const response = await fetchAllAds({ page: page, search: searchTerm, limit: limit }).unwrap();
  //     setRows(response?.data?.ads || []);
  //     setTotalCount(response?.data?.count || 0);
  //   }
  //   catch (e: any) {
  //     errorToast(e?.message);
  //   }
  // }


  const { data, isError, isSuccess, isLoading } = useGetAllAdsQuery({
    page: page, search: searchTerm, limit: limit
  })

  const handleDeleteAd = async (userId: any) => {
    try {
      const response = await deleteAd({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Ad deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  const fetchGetApi = async () => {
    try {
      let response = await getSettings({}).unwrap();
      if (response?.statusCode === 200) {
        setShortPrice(response?.data?.shortBannerTokens)
        setLongPrice(response?.data?.longBannerTokens)
      }
    } catch (e: any) {
      console.error("Failed to fetch settings:", e);
      errorToast(e?.message);
    }
  };

  const postPriceApi = async () => {

    const body = {
      shortBannerTokens: Number(shortPrice),
      longBannerTokens: Number(longPrice)
    }
    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      let response = await addSettings(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast("Price Updated Successfully")
      }
    } catch (e: any) {
      errorToast(e?.data?.message);
    }
  };


  const changeAdsStatus = async (userId: string, userStatus: boolean) => {
    try {
      let body = {
        isBlocked: String(userStatus)
      }
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({ body: encryptedBody, id: userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Status updated successfully");
      }
    }
    catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  }

  // useEffect(() => {
  //   fetchAllAddsApi()
  // }, [page, debouncedSearchTerm, searchTerm, limit])

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.ads || []);
      setTotalCount(res?.count || 0);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    fetchGetApi()
  }, [])

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Ads</h1>
        </div>

        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-ads/add")}
              >
                <AddIcon />
                Add Ads
              </Button>
            </Box>
          </Box>
          <Box className="custom_tabs" sx={{ marginTop: "10px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >

              <Tab label="Ads " {...a11yProps(0)} />
              <Tab label="Price Criteria" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Property Name</TableCell>
                      <TableCell>Current Status</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No ads found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1.map((row1: {
                        endDate: Date;
                        isBlocked: any; name: any; title: any; price: any; categoryName: any; propertyType: number; _id: any; images: any, agentName: any
                      }, i: number) => (
                        <TableRow key={row1?._id || i}>
                          <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                          <TableCell align="center">
                            <figure onClick={() => navigate(`/manage-ads/details/${row1?._id}`)} className="user_img">
                              <img style={{ borderRadius: 0 }} src={row1?.images?.[0] || `/static/images/product1.png`} alt="" />
                            </figure>
                          </TableCell>
                          <TableCell align="center">{row1?.title || "-"}</TableCell>
                          <TableCell align="center" style={{fontWeight:"bolder"}}>{moment(row1?.endDate).isBefore(today)  ? "Expired" : "Live"}</TableCell>
                          <TableCell>
                            <Switch
                              defaultChecked={!row1.isBlocked}
                              onChange={(e) => {
                                const isChecked = !e.target.checked;
                                changeAdsStatus(row1?._id, isChecked);
                              }}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton onClick={() => navigate(`/manage-ads/details/${row1?._id}`)}>
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-ads/edit/${row1?._id}`, {
                                    state: { type: "edit" },
                                  })
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row1?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteAd(selectedId)}
                name="Ad"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Card className="cards">
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Long Banner Price (per day)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="longPrice"
                        variant="outlined"
                        inputProps={{ maxLength: 5 }}
                        fullWidth
                        placeholder="Enter Price"
                        value={longPrice}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setLongPrice(val.target.value)
                          }
                        }}

                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Short Banner Price (per day)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="shortPrice"
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Price"
                        inputProps={{ maxLength: 5 }}
                        value={shortPrice}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setShortPrice(val.target.value)
                          }
                        }}
                      />
                    </Grid>

                  </Grid>
                  <div className="form_btn">
                    <Button size="small" className="btn btn_primary" disabled={!shortPrice || !longPrice} style={{ minWidth: "55px" }} onClick={() => postPriceApi()}>
                      Save
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </CustomTabPanel>

          </Box>
        </Card>
      </div>

    </div>
  );
};

export default ManageAds;
