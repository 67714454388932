import { AllFaq, CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type AddSettings = {
  message(message: any): unknown;
  tokenPrice: number;
  token_buy: number;
  token_rent: number;
};


export const PostAddSettings = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addSettings: builder.mutation<
      CommonResponseType & { data: AddSettings },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.settings,
        method: "POST",
        body,
      }),
      invalidatesTags:["GETSETTINGS"]
    }),

    getAllSettings: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.settings,
        method: "GET",
      }),
      keepUnusedDataFor:5,
      providesTags:["GETSETTINGS"]
    }),
  
  }),
});

export const {
  useAddSettingsMutation,
  useLazyGetAllSettingsQuery,
  useGetAllSettingsQuery
} = PostAddSettings;
