import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody, CompanyRequest } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string;
};



export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPaymentRequest: builder.query<
      CommonResponseType & { data: any },
      {
        page?: number;
        search?: string;
        limit: number;
        status?: number;
        range?: {
          from: any;
          to: any;
        };
      }
    >({
      query: ({ page, search, limit, status, range }) => {
        let url = `${END_POINTS.user}/${END_POINTS.affiliateRequests}?referralRequestStatus=${status}&page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}`;
        return {
          url: url,
          method: "GET",
        };
      },
    
    }),
    

    PostPaymentReason: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.user}/${id}/${END_POINTS.affiliateRequests}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllPaymentRequestQuery,
  usePostPaymentReasonMutation
} = authApi;
