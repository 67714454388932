import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};
 
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
     
    getAllChats: builder.query<CommonResponseType & { data: any }, {
       page?: number; search?: string;limit:number;
       range?:{ from: string | undefined;
        to: string | undefined;};
        chatType?:string|number
      }>({
      query: ({ page, limit ,range,chatType,search}) => {
        let url = `${END_POINTS.chats}?page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}&chatType=${chatType}`;
       
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor:3,
      providesTags:["GETCHAT"]
    }),
    
    
   
    getSpecificChatById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.chats}/${userId}`,
        method: "GET"
      })
    }),
   
    deleteChatById:  builder.mutation<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.chats}/${userId}`,
          method: "DELETE",
        }),
        invalidatesTags:["GETCHAT"]
      }),
 
  }),
});

export const {
    useLazyGetAllChatsQuery,
    useGetAllChatsQuery,
    useLazyGetSpecificChatByIdQuery,
    useDeleteChatByIdMutation,
 
} = authApi;
