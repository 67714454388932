import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  data: any;
  statusCode: number;
  message: string;
  s3Location?: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddProperty: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.property,
        method: "POST",
        body,
      }),
      invalidatesTags:["GETPROPERTY"]
    }),
    getPendingProperty: builder.query<
      CommonResponseType & { data: any },
      {
        page?: number;
        search?: string;
        limit: number;
        propertyType?: string;
        category?: string;
        price?: string;
        agentId?: string;
        propertyName?: number;
        isDraftSaved?: boolean;
        companyId?: string;
        minPrice?: number | string;
        maxPrice?: number | string;
        startDate?: string;
        endDate?: string;
        listingType?: number | string;
        offerStatus?: number;
        lat?: number;
        long?: number;
        isExport?:boolean;
      }
    >({
      query: ({
        page,
        search,
        limit,
        propertyType,
        category,
        price,
        agentId,
        propertyName,
        isDraftSaved,
        companyId,
        minPrice,
        maxPrice,
        startDate,
        endDate,
        listingType,
        offerStatus,
        lat,
        long,
        isExport
      }) => {
        let url = `${END_POINTS.property}?page=${page}&search=${search}&limit=${limit}&propertyTypeId=${propertyType}&companyId=${companyId}&categoryId=${category}&price=${price}&agentId=${agentId}&minPrice=${minPrice}&maxPrice=${maxPrice}&startDate=${startDate}&endDate=${endDate}&listingType=${listingType}&offerStatus=${offerStatus}&lat=${lat}&long=${long}&propertyName=${propertyName}${isExport?'&isExport=true':''}${isDraftSaved ? `&isDraftSaved=true` : ""}`;

        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["GETPROPERTY"]
    }),


    getSpecificPropertyById: builder.query<
      CommonResponseType & { data: any },
      { userId: any; isDraft?: boolean }
    >({
      query: ({ userId, isDraft }) => ({
        url: `${END_POINTS.property}/${userId}${isDraft ? `?isDraftSaved=true` : ""}`,
        method: "GET",
      }),
    }),
    popertyChatById: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.chats}?propertyId=${userId}`,
        method: "GET",
      }),
    }),

    EditProperty: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.property}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags:["GETPROPERTY"]
    }),

    deleteByIdProperty: builder.mutation<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.property}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags:["GETPROPERTY"]
    }),
    getAmenitiesListing: builder.query<CommonResponseType, {}>({
      query: ({ }) => {
        let url = END_POINTS.amenitiesListing;

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getpropertYTypeListing: builder.query<CommonResponseType, {}>({
      query: ({ }) => {
        let url = END_POINTS.propertyTypeListing;

        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getPropertyRequestForUnderOffer: builder.query<
      CommonResponseType & { data: any },
      {
        page?: number;
        search?: string;
        limit: number;
        startDate?: string;
        endDate?: string;
        underOfferStatus?: number;
      }
    >({
      query: ({
        page,
        search,
        limit,
        startDate,
        endDate,
        underOfferStatus,

      }) => {
        let url = `${END_POINTS.property}?page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&underOfferStatus=${underOfferStatus}&offerStatus=3`;
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getPropertyRequestForClaimedProperty: builder.query<
      CommonResponseType & { data: any },
      {
        page?: number;
        search?: string;
        limit: number;
        startDate?: string;
        endDate?: string;
        isClaimDocStatus?: number;

      }
    >({
      query: ({
        page,
        search,
        limit,
        startDate,
        endDate,
        isClaimDocStatus,

      }) => {
        let url = `${END_POINTS.property}?page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&isClaimDocStatus=${isClaimDocStatus}&offerStatus=2`;
        return {
          url: url,
          method: "GET",
        };
      },
    }),


    getPropertyForCategoryDetails: builder.query<
      CommonResponseType & { data: any },
      {
        page?: number;
        search?: string;
        limit: number;
        category?: string;
      }
    >({
      query: ({
        page,
        limit,
        category,
      }) => {
        let url = `${END_POINTS.property}?page=${page}&limit=${limit}&categoryId=${category}`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),



  }),
});

export const {
  usePostAddPropertyMutation,
  useGetPendingPropertyQuery,
  useLazyGetPendingPropertyQuery,
  useLazyGetSpecificPropertyByIdQuery,
  useEditPropertyMutation,
  useDeleteByIdPropertyMutation,
  useLazyGetAmenitiesListingQuery,
  useLazyGetpropertYTypeListingQuery,
  useLazyPopertyChatByIdQuery,
  useLazyGetPropertyRequestForUnderOfferQuery,
  useLazyGetPropertyRequestForClaimedPropertyQuery,
  useLazyGetPropertyForCategoryDetailsQuery
} = authApi;
