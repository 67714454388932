import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import Messages from "../../features/users/Messages";
import { useLazyGetSpecificPropertyByIdQuery } from "../../services/property";
import PropertyMessages from "../../features/Property/PropertyMessage";
import { ImageModal } from "../../components";

const PropertyDetails = () => {
  const { _id } = useParams();
  const { state } = useLocation();
  const [fetchApi, { isLoading }] = useLazyGetSpecificPropertyByIdQuery();
  const navigate = useNavigate();
  const [ByIdDetails, setByIdDetails] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);


  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  function formatNumberWithCommas(value: string) {
    const [integer, decimal] = value.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  }

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);


  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Property Listing Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/property-listing", { state: state?.tab })}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  {ByIdDetails?.images?.length > 0 && (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Images</Typography>
                        <div className="profile_img_grid">
                          {ByIdDetails.images.map((image: string, index: number) => (
                            <figure className="profile_img" style={{ borderRadius: 0 }}>
                              <img
                                style={{ borderRadius: 0 }}
                                src={image}
                                alt={`property-${index}`}
                              />
                              <div className="overlay">
                                <img
                                  onClick={() => handleOpenModal(image)}
                                  src='/static/images/visibility.png' alt='img'
                                />
                              </div>
                            </figure>
                          ))}
                        </div>
                      </Box>
                    </Grid>
                  )}
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5" style={{ paddingTop: 10 }}>Property Name</Typography>
                      <Typography component="p">{ByIdDetails?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5" style={{ paddingTop: 10 }}>Owner</Typography>
                      <Typography component="p">{ByIdDetails?.userId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">{ByIdDetails?.price ? formatNumberWithCommas(String(ByIdDetails?.price)) : "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Category</Typography>
                      <Typography component="p">{ByIdDetails?.categoryId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Type</Typography>
                      <Typography component="p">{ByIdDetails?.propertyTypeId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Beds</Typography>
                      <Typography component="p">{ByIdDetails?.beds || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Total Impressions</Typography>
                      <Typography component="p">{ByIdDetails?.impressions || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Total Clicks</Typography>
                      <Typography component="p">{ByIdDetails?.propertyCount || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Total Leads</Typography>
                      <Typography component="p">{ByIdDetails?.leads?.totalLeads || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Permit number</Typography>
                      <Typography component="p">{ByIdDetails?.advertisementPermitNo || "-"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Baths</Typography>
                      <Typography component="p">{ByIdDetails?.baths || "-"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Size</Typography>
                      <Typography component="p">{ByIdDetails?.size || "-"}</Typography>
                    </Box>
                  </Grid>
                  {ByIdDetails?.serviceCharges ? (
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Service Charges</Typography>
                        <Typography component="p">{ByIdDetails?.serviceCharges || "-"}</Typography>
                      </Box>
                    </Grid>
                  ) : ("")}
                  {ByIdDetails?.finacialStatus ? (
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Financial Status</Typography>
                        <Typography component="p">{ByIdDetails?.finacialStatus === 1 ? "Cash" : "Mortgage"}</Typography>
                      </Box>
                    </Grid>
                  ) : ("")}

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Address</Typography>
                      <Typography component="p">{ByIdDetails?.address || "-"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Key Features</Typography>
                      <Typography component="p">{ByIdDetails?.keyFeature || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Specifications</Typography>
                      <Typography component="p">{ByIdDetails?.specifications || "-"}</Typography>
                    </Box>
                  </Grid>
                  {ByIdDetails?.floorPlan ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Floor Plan</Typography>
                        {ByIdDetails?.floorPlan ? (
                          <Box className="upload_image">
                            <label htmlFor="icon-button-file-vdo">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file-vdo"
                                type="file"
                                inputProps={{
                                  type: "file",
                                  accept: "application/pdf",
                                }}
                                disabled={true}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                <img
                                  style={{
                                    borderRadius: "5px",
                                    width: "300px",
                                    height: "200px",
                                    border: "solid 2px whitesmoke",
                                    objectFit: "contain"
                                  }}
                                  onClick={() => ByIdDetails?.floorPlan ? window.open(ByIdDetails?.floorPlan) : null}
                                  src="/static/images/pdf.png"
                                  alt=""
                                />
                              </Button>
                            </label>
                          </Box>
                        ) : ("")}
                      </Box>

                    </Grid>
                  ) : ("")}



                  {ByIdDetails?.amenitiesId?.length > 0 && (

                    <Grid item xs={12}>
                      <Typography component="h5" >Amenities</Typography>
                      <Grid container spacing={2}>
                        {ByIdDetails.amenitiesId.map((amenity: any, index: number) => (
                          <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                            <Typography component="p">{amenity?.name}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <div className="dashboard" style={{ paddingTop: 35 }}>
          <h2 className="mn_hdng">Property Chat Details</h2>
        </div>
        <PropertyMessages />
      </div>
      <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} />

    </div>
  );
};

export default PropertyDetails;
