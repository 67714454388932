import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Home, CheckCircle, Star, People } from "@mui/icons-material";
import useGetFilters from "../../hooks/useGetFilter";
import { useLazySuperAgentInsightsOverviewQuery } from "../../services/insights";
import Loader from "../../helpers/constants/Loader";
import { errorToast } from "../../helpers";
import ClearIcon from "@mui/icons-material/Clear";

export const SuperAgentOverview = () => {
  const [autocomplete, setAutocomplete] = useState(null); // state to store the autocomplete for the google places api
  const [location, setLocation] = useState("");
  const [filter, setFilter] = useState({
    category: "",
    location: {
      lat: 0,
      lng: 0,
    },
  });
  const [insightsData, setInsightsData] = useState<any>();
  const { cat }: any = useGetFilters();
  const [getData, { isLoading }] = useLazySuperAgentInsightsOverviewQuery();

  const fetchdata = async () => {
    try {
      const res = await getData({
        categoryId: filter?.category || "",
        latitude: filter?.location?.lat || 0,
        longitude: filter?.location?.lng || 0,
      }).unwrap();
      if (res?.statusCode === 200) {
        setInsightsData(res?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "Something went wrong");
    }
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  // loading fucntion for the google places api
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  // Function to listen the google places api changes
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place) {
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();
        setLocation(place?.formatted_address);
        setFilter({
          ...filter,
          location: {
            lat,
            lng,
          },
        });
      }
    }
  };

  const ScoreCard = ({ value, label, subLabel }: any) => (
    <div className="score-card">
      <CircularProgress
        variant="determinate"
        value={value * 10}
        size={80}
        thickness={4}
      />
      <h4>{value}</h4>
      <p className="fill_box">{label}</p>
      <p>{subLabel}</p>
    </div>
  );

  const DummyCard = ({ value, label, subLabel }: any) => (
    <div className="score-card">
      <CircularProgress
        variant="determinate"
        value={10 * 10}
        size={80}
        thickness={4}
        sx={{ color: "#B0BEC5" }}
      />
      <h4>{value}</h4>
      <p className="fill_box">{label}</p>
      <p>{subLabel}</p>
    </div>
  );

  const MetricCard = ({ icon, title, value, change }: any) => (
    <div className="metric-card bx_shdw">
      <div className="metric-header">
        {icon}
        <h6>{title}</h6>
      </div>
      <h4>{value}</h4>
      <Typography
        variant="body2"
        className={
          change.startsWith("+") ? "positive-change" : "negative-change"
        }
      >
        {change}
      </Typography>
    </div>
  );

  useEffect(() => {
    fetchdata();
  }, [filter]);

  return (
    <div>
      <Loader isLoad={isLoading} />
      <Box className="a">
        <Box className="page_heading main flex">
          <div className="l_s">
            <h3>Company Insights</h3>
            <p>Based on past 30 days</p>
          </div>
          <Box className="filters">
            <div className="control_group">
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                options={{
                  types: ["political"],
                  componentRestrictions: { country: "ae" },
                }}
              >
                <TextField
                  className="text_field"
                  hiddenLabel
                  name="address"
                  placeholder="Address"
                  value={location}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                  }}
                  InputProps={{
                    endAdornment:
                      location !== "" ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setLocation("");
                              setFilter({
                                ...filter,
                                location: {
                                  lat: 0,
                                  lng: 0,
                                },
                              });
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                      setFilter({
                        ...filter,
                        location: {
                          lat: 0,
                          lng: 0,
                        },
                      });
                    } else {
                      setLocation(val.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </Autocomplete>
            </div>
            <div className="control_group">
              <Select
                labelId="category-label"
                id="category-select"
                value={filter.category}
                onChange={(value) => {
                  handleFilterChange("category", value.target.value);
                }}
                displayEmpty
              >
                <MenuItem value={""} disabled>
                  Category
                </MenuItem>
                {cat?.category?.map((item: any, index: any) => (
                  <MenuItem key={item?.id} value={item?._id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
        </Box>

        <div className="company_grid">
          <div className="company_item box1 bx_shdw">
            <h6>Your competitive score</h6>
            <p>
              Your competitive score is generated by ADA, our Agent Data
              Algorithm that rewards the best real estate agents based on
              performance.
            </p>
            <Box className="score-container">
              {insightsData?.yourScoreRating === 0 ? (
                <DummyCard value={0} label="Very Poor" subLabel="Your Score" />
              ) : (
                <ScoreCard
                  value={insightsData?.yourScoreRating || 0}
                  label="Good"
                  subLabel="Your Score"
                />
              )}
              <DummyCard value={0} label="Very Poor" subLabel="Market Avg" />

              {/* <ScoreCard
                                value={4.4}
                                label="Average"
                                subLabel="Market Avg"
                            /> */}
              {insightsData?.competitiveScoreRating === 0 ? (
                <DummyCard
                  value={0}
                  label="Very Poor"
                  subLabel="Nearest 5 competitors"
                />
              ) : (
                <ScoreCard
                  value={insightsData?.competitiveScoreRating || 0}
                  label="Good"
                  subLabel="Nearest 5 competitors"
                />
              )}
            </Box>
            {/* <Box component="a">How to improve your score →</Box> */}
          </div>
          <div className="company_item">
            <div className="totl_lstng_mn">
              <div>
                <MetricCard
                  icon={<Home />}
                  title="Total listings"
                  value={insightsData?.yourScore?.totalProperties || 0}
                  change="+0%"
                />
              </div>
              <div>
                <MetricCard
                  icon={<CheckCircle />}
                  title="Verified Listings"
                  value={insightsData?.yourScore?.liveProperties || 0}
                  change="+0%"
                />
              </div>
              <div>
                <MetricCard
                  icon={<Star />}
                  title="Quality Score"
                  value={insightsData?.yourScore?.qualityScore || 0}
                  change="-0%"
                />
              </div>
              <div>
                <MetricCard
                  icon={<People />}
                  title="Interest"
                  value={insightsData?.yourScore?.interest || 0}
                  change="+0%"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="market-insights-card bx_shdw">
          <h6>Market Insights</h6>
          <p>Your position in the market</p>
          <Box className="market-position">
            <Typography>6th</Typography>
            <Typography className="on_pos">7th</Typography>
            <Box className="progress-bar">
              <Box className="progress-fill"></Box>
            </Box>
            <Typography>8th</Typography>
          </Box>
          <Typography variant="body2">
            Keep it up! Reach 556 more leads to achieve the next position. Check
            below how to progress towards your goal.
          </Typography>
        </div>
      </Box>
    </div>
  );
};
