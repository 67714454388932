
import { AllFaq, CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";


type CommonResponseType = {
  statusCode: number;
  message: string;
};

type postAddFaq = {
  question: string;
  answer: string;
};

type getAllFaq = {
  termsAndConditions: string;
  contactSupport: any;
  aboutUs: string;
  email: string;
  cookiesPolicy: string;
  legal: string;
  phone_no: string;
  privacyPolicy: string;
  country_code: string;
  faq(faq: any): unknown;
  data: AllFaq;
  boostListing: string;
  qualityScore: string;
  verifyListing: string;
  superAgentCreteria: string;
  customerAgreement: string;
  termConditionsCrm: string;
  termConditionsPremium: string;
  termConditionsLive: string;
  termUseLive: string;
  termConditionsExpertMaster: string;
};



export const Managefaq = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addFaq: builder.mutation<
      CommonResponseType & { data: postAddFaq },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.addCms,
        method: "POST",
        body,
      }),
      invalidatesTags:["GETCMS"]
    }),

    getAllFaq: builder.query<CommonResponseType & { data: getAllFaq }, {}>({
      query: () => ({
        url: END_POINTS.getCms,
        method: "GET",
      }),
      keepUnusedDataFor:3,
      providesTags:["GETCMS"]
    }),


  }),
});

export const {
  useGetAllFaqQuery,
  useAddFaqMutation
} = Managefaq;
