import React, { useEffect, useLayoutEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
  TextField,
  Popper,
  ClickAwayListener,
  Paper,
  Chip,
  InputAdornment,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useEditUserMutation,
  useDeleteByIduserMutation,
  useGetPendingCompanyQuery,
} from "../../services/users";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { useLazyExportCompanyCsvQuery } from "../../services/dashboard";
import Loader from "../../helpers/constants/Loader";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { Clear } from "@mui/icons-material";
import ReasonModal from "../../components/RejectReason";
import { getFromStorage, STORAGE_KEYS } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  getAgentCount,
  getCompanyCount,
  getCompanyRequestCount,
  getEnquriesCount,
  getFeedbackCount,
  getPaymentRequestCount,
  getPropertyRequestCount,
  getShortTermRequestCount,
  setRequestCount,
} from "../../reducers/generalSlice";
import { AddTokenModal } from "../../components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCompany = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<string>("");
  const agentCount = useAppSelector(getAgentCount);
  const companyCount = useAppSelector(getCompanyCount);
  const companyRequestCount = useAppSelector(getCompanyRequestCount);
  const propertyRequestCount = useAppSelector(getPropertyRequestCount);
  const paymentRequestCount = useAppSelector(getPaymentRequestCount);
  const shortTermRequestCount = useAppSelector(getShortTermRequestCount);
  const [exportCsv] = useLazyExportCompanyCsvQuery();
  const [rows1, setRows1] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editUSERbyId] = useEditUserMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount1] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [token, setToken] = useState<string>("")
  const [openToken, setOpenToken] = useState<boolean>(false)
  const [editCompanybyId] = useEditUserMutation();
  const [selectedId, setSelectedId] = useState<string>("");
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [receiver, setReceiver] = useState("1");
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows1([]);
  };
  const { state } = useLocation();
  const [deleteUserById] = useDeleteByIduserMutation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };

  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("");
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const handleClose = () => {
    setOpen1(false);
  };
  const [dataCount, setDataCount] = useState({
    pendingRequests: 0,
    aproovedRequests: 0,
    rejectedRequests: 0,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const {
    data,
    isError,
    isSuccess,
    isLoading: load,
  } = useGetPendingCompanyQuery({
    page: page,
    search: debouncedSearchTerm?.trim(),
    limit: limit,
    isAdminAprooved: value + 1,
    agencyType: type ? type : "",
    range: {
      from: range?.from
        ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : "",
      to: range?.to
        ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : "",
    },
  });

  const changeUserStatus = async (userId: string, userStatus: boolean) => {
    setIsLoading(true);

    try {
      let body = {
        isBlocked: String(userStatus),
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({
        body: encryptedBody,
        id: userId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);

        successToast("Status updated successfully");
      }
    } catch (error: any) {
      setIsLoading(false);

      errorToast(error?.message);
      console.error(error);
    }
  };


  const AssignTokens = async () => {
    setIsLoading(true);
    try {
      let body = {
        adminBonusTokens: token ? Number(token) : 0,
      };
      console.log(body, "assign token");

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({
        body: encryptedBody,
        id: selectedId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        setOpenToken(false);
        setToken("")
        successToast("Tokens assigned successfully");
      }
    } catch (error: any) {
      setIsLoading(false);
      setOpenToken(false)
      setToken("")
      errorToast(error?.message);
      console.error(error);
    }
  };

  const handleDeleteCompany = async (userId: any) => {
    setIsLoading(true);

    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          setRequestCount({
            agents: agentCount,
            company: companyCount ? companyCount - 1 : 0,
            companyRequests: companyRequestCount,
            propertyRequest: propertyRequestCount,
            paymentRequestCount: paymentRequestCount,
            shortTermRequests: shortTermRequestCount
          })
        );
        successToast("Company deleted successfully");
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  const handleApprove = async (userId: any) => {
    const body = {
      isAdminAprooved: 2,
    };

    try {
      setIsLoading(true);

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editCompanybyId({
        body: encryptedBody,
        id: userId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        setValue(1);
        dispatch(
          setRequestCount({
            agents: agentCount,
            company: companyCount ? companyCount - 1 : 0,
            companyRequests: companyRequestCount,
            propertyRequest: propertyRequestCount,
            paymentRequestCount: paymentRequestCount,
            shortTermRequests: shortTermRequestCount

          })
        );
        successToast("Request accepted successfully");
      }
    } catch (error: any) {
      setIsLoading(false);

      errorToast(error?.data?.message || "");
    }
  };

  const handleReject = async () => {
    const body = {
      isAdminAprooved: 3,
      docRejectionReason: rejectionReason,
    };

    try {
      setIsLoading(true);

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editCompanybyId({
        body: encryptedBody,
        id: selectedCompany,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        setRejectionReason("");
        setValue(2);
        dispatch(
          setRequestCount({
            agents: agentCount,
            company: companyCount ? companyCount - 1 : 0,
            companyRequests: companyRequestCount,
            propertyRequest: propertyRequestCount,
            paymentRequestCount: paymentRequestCount,
            shortTermRequests: shortTermRequestCount

          })
        );
        successToast("Request rejected successfully");
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({
        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        search: debouncedSearchTerm?.trim(),
      }).unwrap();

      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  useLayoutEffect(() => {
    if (state) {
      setValue(state);
    }
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows1(res?.user || []);
      setTotalCount1(res?.totalCount);
      setDataCount({
        pendingRequests: res?.pendingRequests || 0,
        aproovedRequests: res?.aproovedRequests || 0,
        rejectedRequests: res?.rejectedRequests || 0,
      });
    }
  }, [isSuccess, data]);

  return (
    <div>
      <Loader isLoad={isLoading || load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Companies</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className="control_group" style={{ width: "250px" }}>
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment
                        position="end"
                        style={{ marginLeft: -50 }}
                      >
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined,
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Popper
                open={open1}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper
                    elevation={3}
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <div
                      style={{
                        padding: 16,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Chip
                          label="Last Week"
                          variant={
                            selectedChip === "lastWeek" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastWeek")}
                        />
                        <Chip
                          label="Last Month"
                          variant={
                            selectedChip === "lastMonth" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastMonth")}
                        />
                        <Chip
                          label="Last Year"
                          variant={
                            selectedChip === "lastYear" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastYear")}
                        />
                      </div>

                      <DayPicker
                        className="react-day-picker"
                        mode="range"
                        selected={range}
                        onSelect={handleSelect}
                        // numberOfMonths={10}
                        month={month}
                        onMonthChange={handleMonthChange}
                      // styles={{
                      //   months: { display: "flex", gap: "16px" },
                      //   month: { margin: 0 },
                      // }}
                      />
                    </div>
                  </Paper>
                </ClickAwayListener>
              </Popper>
              <div className="childFilter">
                <Select
                  className="childFilter"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  <MenuItem value="1">Real Estate Broker</MenuItem>
                  <MenuItem value="2">Short Term Rentals</MenuItem>
                </Select>
                {type !== "" && (
                  <IconButton
                    onClick={() => setType("")}
                    style={{
                      position: "absolute",
                      top: "30%",

                      transform: "translateY(-50%)",
                    }}
                  >
                    <Clear />
                  </IconButton>
                )}
              </div>

            </Box>
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-company/add")}
              >
                <AddIcon /> Add Company
              </Button>
              <Button className="btn btn_primary" onClick={handleExportCsv} disabled={rows1?.length === 0 ? true : false}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <Box className="custom_tabs">
            <Tabs
              style={{ marginTop: "20px" }}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab
                label={`Pending (${dataCount?.pendingRequests})`}
                {...a11yProps(0)}
              />
              <Tab
                label={`Approved (${dataCount?.aproovedRequests})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Rejected (${dataCount?.rejectedRequests})`}
                {...a11yProps(2)}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Company name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Tokens</TableCell>
                      <TableCell>Request</TableCell>

                      {/* <TableCell>Account Status</TableCell> */}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No company found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: any) => (
                        <TableRow key={row?._id}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>

                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(
                                  `/manage-company/details/${row?._id}`,
                                  { state: { tab: 0 } }
                                )
                              }
                              className="user_img"
                            >
                              <img
                                src={row?.image || "/static/images/picture.png"}
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          <TableCell>
                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.token || "-"}</TableCell>

                          <TableCell>
                            <Select
                              fullWidth
                              className="select_div reqst_Select"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={receiver}
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              displayEmpty
                            // onChange={(value: any) => {
                            //   handleReceiverChange(value, row?._id);
                            // }}
                            >
                              <MenuItem value="1" disabled>
                                Pending
                              </MenuItem>

                              <MenuItem
                                value="2"
                                onClick={() => handleApprove(row?._id)}
                              >
                                Approve
                              </MenuItem>
                              <MenuItem
                                value="3"
                                onClick={() => {
                                  setSelectedCompany(row?._id);
                                  setOpenReject(true);
                                }}
                              >
                                Reject
                              </MenuItem>
                            </Select>
                          </TableCell>


                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-company/details/${row?._id}`,
                                    { state: { tab: 0 } }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-company/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Company"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Company name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>ORN</TableCell>
                      <TableCell>Total Agent</TableCell>
                      <TableCell>Total Properties</TableCell>
                      <TableCell>Emirate Id</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Tokens</TableCell>

                      <TableCell>Account Status</TableCell>
                      <TableCell>Send Tokens</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No company found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: any) => (
                        <TableRow key={row?._id}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>

                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(
                                  `/manage-company/details/${row?._id}`,
                                  {
                                    state: { insights: true, tab: 1 },
                                  }
                                )
                              }
                              className="user_img"
                            >
                              <img
                                src={row?.image || "/static/images/picture.png"}
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          <TableCell>
                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.ORN || "-"}</TableCell>
                          <TableCell>{row?.totalAgents ||"-"}</TableCell>
                          <TableCell>{row?.totalProperties ||"0"}</TableCell>
                          <TableCell>{row?.emiratesId ||"-"}</TableCell>
                          <TableCell>{row?.address ||"-"}</TableCell>
                          <TableCell>{row?.token || "-"}</TableCell>

                          <TableCell>
                            <Switch
                              defaultChecked={!row.isBlocked}
                              onChange={(e) => {
                                const isChecked = !e.target.checked;
                                changeUserStatus(row._id, isChecked);
                              }}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => { setSelectedId(row?._id); setOpenToken(true) }} className="btn btn_primary">Send Token</Button>
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-company/details/${row?._id}`,
                                    {
                                      state: { insights: true, tab: 1 },
                                    }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-company/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Company"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Company Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Company name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Address</TableCell>

                      {/* <TableCell>Account Status</TableCell> */}

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No company found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: any) => (
                        <TableRow key={row?._id}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>

                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(
                                  `/manage-company/details/${row?._id}`,
                                  { state: { tab: 2 } }
                                )
                              }
                              className="user_img"
                            >
                              <img
                                src={row?.image || "/static/images/picture.png"}
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          <TableCell>
                            {row?.phoneNo ? row?.dialCode + row?.phoneNo : "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.token || "-"}</TableCell>

                          {/* <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={receiver}
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handleReceiverChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Accept">Approve</MenuItem>
                            <MenuItem value="Reject">Reject</MenuItem>
                          </Select>
                        </TableCell> */}

                          {/* <TableCell>
                          <Switch {...label} defaultChecked size="small" />
                        </TableCell> */}
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-company/details/${row?._id}`,
                                    { state: { tab: 2 } }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-company/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Company"
              />
            </CustomTabPanel>
          </Box>
          <ReasonModal
            open={openReject}
            setOpen={setOpenReject}
            handleDelete={handleReject}
            reason={rejectionReason}
            setReason={setRejectionReason}
          />
          <AddTokenModal
            open={openToken}
            setOpen={setOpenToken}
            token={token}
            setToken={setToken}
            assignToken={AssignTokens}
          />
        </Card>
      </div>
    </div >
  );
};

export default ManageCompany;
