import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchBar from "../../components/SearchBar";
import {
  useEditUserMutation,
  useDeleteByIduserMutation,
  useLazyGetSpecificCompanyAllAgentsByIdQuery,
} from "../../services/users";

import WarnModal from "../../components/WarnModal";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Pagination from "../../components/Pagination";

const CompanyAgents = () => {
  const { _id } = useParams();
  const [editUSERbyId] = useEditUserMutation();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [AgentList, { isLoading }] =
    useLazyGetSpecificCompanyAllAgentsByIdQuery();
  const [limit, setLimit] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / limit);
  const [page, setPage] = useState(1);
  const [deleteUserById] = useDeleteByIduserMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setByIdDetails([]);
  };

  const fetchDataById = async () => {
    try {
      const response = await AgentList({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data?.agents || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById();
  }, [_id]);

  function createData(
    id: number,
    date: string,
    title: string,
    message: string,
    orders: boolean,
    status: boolean
  ) {
    return { id, date, title, message, orders, status };
  }

  const rows = [
    createData(
      1,
      "29/09/2023",
      "Title",
      "Message",
      true, // Orders-based changes and updates
      true // Status Updates (confirmed)
    ), // Example data, replace with actual data from database
  ];
  const handleDeleteUser = async (userId: any) => {
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Agent deleted successfully");
        fetchDataById();
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const changeUserStatus = async (userId: string, userStatus: boolean) => {
    try {
      let body = {
        isBlocked: String(userStatus),
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({
        body: encryptedBody,
        id: userId,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Status updated successfully");
      }
    } catch (error: any) {
      errorToast(error?.message);
      console.error(error);
    }
  };
  return (
    <>
      <Card className="cards">
        <TableContainer className="table_container" style={{ marginTop: 0 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Tokens</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Account Status</TableCell>
                {/* <TableCell>Message</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ByIdDetails?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No Agent found
                  </TableCell>
                </TableRow>
              ) : (
                ByIdDetails.map((row: any, index: any) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>
                      <figure className="user_img">
                        <img
                          src={
                            row?.image || "/static/images/user_placeholder.png"
                          }
                          alt=""
                        />
                      </figure>
                    </TableCell>
                    <TableCell>{row?.name || "-"}</TableCell>
                    <TableCell>{row?.email || "-"}</TableCell>
                    <TableCell>{row?.dialCode + row?.phoneNo || "-"}</TableCell>
                    <TableCell>{row?.token || "-"}</TableCell>
                    <TableCell>{row?.address || "-"}</TableCell>
                    {/* <TableCell>{row.title}</TableCell> */}
                    {/* <TableCell>{row.message}</TableCell> */}
                    <TableCell>
                      <Switch
                        defaultChecked={!row.isBlocked}
                        onChange={(e) => {
                          const isChecked = !e.target.checked;
                          changeUserStatus(row._id, isChecked);
                        }}
                        size="small"
                      />
                    </TableCell>

                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-agents/details/${row?._id}`)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {setByIdDetails?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={setByIdDetails}
          page={page}
          limit={limit}
          setLimit={setLimit}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteUser(selectedId)}
        name="Agent"
      />
    </>
  );
};

export default CompanyAgents;
