import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid, Paper, styled } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  useLazyGetPropertyGraphQuery,
  useLazyGetRevenueGraphQuery,
  useLazyGetUserGraphQuery,
  useLazyGetAgentGraphQuery,
  useLazyGetCompanyGraphQuery,
} from "../../services/dashboard";
import Loader from "../../helpers/constants/Loader";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Analytics = () => {
  const [UserData, { isLoading }] = useLazyGetUserGraphQuery();
  const [AgentData] = useLazyGetAgentGraphQuery();
  const [CompanyData] = useLazyGetCompanyGraphQuery();
  const [RevenueData] = useLazyGetRevenueGraphQuery();
  const [PropertyData] = useLazyGetPropertyGraphQuery();
  const [user, serUser] = useState<any>([]);
  const [revenue, setRevenue] = useState<any>([]);
  const [property, setProperty] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [selectedType1, setSelectedType1] = useState<string>("weekly");
  const [selectedType2, setSelectedType2] = useState<string>("weekly");
  const [selectedType3, setSelectedType3] = useState<string>("weekly");
  const [selectedType4, setSelectedType4] = useState<string>("weekly");
  const [selectedType5, setSelectedType5] = useState<string>("weekly");
  const fetchPropertyGraph = async (type: string) => {
    try {
      const response = await PropertyData(type).unwrap();
      const formattedData =
        response?.data?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setProperty(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchUsersGraph = async (type: string) => {
    try {
      const response = await UserData(type).unwrap();
      const formattedData =
        response?.data?.totalUsersGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      serUser(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchAgentGraph = async (type: string) => {
    try {
      const response = await AgentData(type).unwrap();
      const formattedData =
        response?.data?.totalUsersGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setAgent(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchCompanyGraph = async (type: string) => {
    try {
      const response = await CompanyData(type).unwrap();
      const formattedData =
        response?.data?.totalUsersGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setCompany(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchRevenueGraph = async (type: string) => {
    try {
      const response = await RevenueData(type).unwrap();
      const formattedData =
        response?.data?.totalRevenueGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setRevenue(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    fetchRevenueGraph(selectedType2);
  }, [selectedType2]);

  useEffect(() => {
    fetchUsersGraph(selectedType1);
  }, [selectedType1]);

  useEffect(() => {
    fetchPropertyGraph(selectedType3);
  }, [selectedType3]);

  useEffect(() => {
    fetchAgentGraph(selectedType4);
  }, [selectedType4]);
  useEffect(() => {
    fetchCompanyGraph(selectedType5);
  }, [selectedType5]);

  const graphUsersData = {
    labels: user.length !== 0 ? user?.map((item: any) => item.label) : [],
    datasets: [
      {
        label: "Total Users",
        data: user.length !== 0 ? user?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };
  const graphAgentData = {
    labels: agent.length !== 0 ? agent?.map((item: any) => item.label) : [],
    datasets: [
      {
        label: "Total Agents",
        data: agent.length !== 0 ? agent?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };
  const graphCompanyyData = {
    labels: company.length !== 0 ? company?.map((item: any) => item.label) : [],
    datasets: [
      {
        label: "Total Companies",
        data:
          company.length !== 0 ? company?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };

  const graphRevenueData = {
    labels: revenue?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Revenue",
        data:
          revenue.length !== 0 ? revenue?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };

  const graphPropertyData = {
    labels: property?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Properties",
        data:
          property.length !== 0 ? property?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };

  const handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType1(newType);
  };
  const handleChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType2(newType);
  };
  const handleChange3 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType3(newType);
  };
  const handleChange4 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType4(newType);
  };
  const handleChange5 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType5(newType);
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Users
                <FormControl>
                  <div>
                    {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                    <NativeSelect
                      defaultValue={selectedType1}
                      onChange={handleChange1}
                      inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </NativeSelect>
                  </div>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </Item>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Revenue
                <FormControl>
                  <div>
                    {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                    <NativeSelect
                      defaultValue={selectedType2}
                      onChange={handleChange2}
                      inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </NativeSelect>
                  </div>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </Item>
          </Grid>
          {/* revenue------ */}
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total
                <FormControl>
                  <div>
                    {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                    <NativeSelect
                      defaultValue={selectedType3}
                      onChange={handleChange3}
                      inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </NativeSelect>
                  </div>
                </FormControl>
              </h2>
              <LineChart data={graphPropertyData} />
            </Item>
          </Grid>
          {/* end revenue-------- */}
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Agents
                <FormControl>
                  <div>
                    {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                    <NativeSelect
                      defaultValue={selectedType4}
                      onChange={handleChange4}
                      inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </NativeSelect>
                  </div>
                </FormControl>
              </h2>
              <LineChart data={graphAgentData} />
            </Item>
          </Grid>
          {/* total companies */}
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Item className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Companies
                <FormControl>
                  <div>
                    {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                    <NativeSelect
                      defaultValue={selectedType5}
                      onChange={handleChange5}
                      inputProps={{
                        name: "report",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </NativeSelect>
                  </div>
                </FormControl>
              </h2>
              <LineChart data={graphCompanyyData} />
            </Item>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Agents
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphSubscription} />
            </div>
          </Grid> */}
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Earnings
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Transactions
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionsData} />
            </div>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
};

export default Analytics;
