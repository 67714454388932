import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Card,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar2 from "../../../components/SearchBar2";
import { isValidInput } from "../../../utils/validation";
import AddIcon from '@mui/icons-material/Add';
import Pagination from "../../../components/Pagination";
import WarnModal from "../../../components/WarnModal";
import { useDeleteByIduserMutation, useLazyGetAllUserQuery } from "../../../services/users";
import { errorToast, successToast } from "../../../helpers";

const ManageAffiliateMembers = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [getallusers] = useLazyGetAllUserQuery();
    const [page, setPage] = useState(1);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedId, setSelectedId] = useState<number>(0);
    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRows1([]);
    };
    const [deleteUserById] = useDeleteByIduserMutation();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    let totalPages = Math.ceil(totalCount / limit);
    const [rows1, setRows1] = useState<any[]>([]);

    function formatNumberWithCommas(value: string) {
        const [integer, decimal] = value.split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
    }

    const fetchData = async (page: number, search: string) => {
        setIsLoading(true);
        try {
            const response = await getallusers({
                page: page,
                search: search,
                limit: limit,
                role: 7,
                startDate: "",
                endDate: ""
            }).unwrap();
            if (response.statusCode === 200) {
                setIsLoading(false);
                setRows1(response?.data?.user || []);
                setTotalCount(response?.data?.totalCount);
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };
    const handleDeleteUser = async (userId: any) => {
        setIsLoading(true);
        try {
            const response = await deleteUserById({ userId }).unwrap();
            if (response?.statusCode === 200) {
                setIsLoading(false);
                successToast("Member deleted successfully");
                fetchData(page, searchTerm);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            errorToast(error?.message || "");
        }
    };



    useEffect(() => {
        fetchData(page, debouncedSearchTerm);
    }, [page, debouncedSearchTerm, limit]);



    return (
        <div>
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Manage Affiliate Members </h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <SearchBar2
                            value={searchTerm}
                            searchTerm={searchTerm}
                            onCross={() => setSearchTerm("")}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            onChange={(val: any) => {
                                if (isValidInput(val.target.value)) {
                                    setSearchTerm(val.target.value);
                                }
                            }}
                        />
                        {/* <Loader isLoad={isLoading} /> */}
                        <Box className="cards_header_right">
                            <Button
                                className="btn btn_primary"
                                onClick={() => navigate("/affiliate-members-add")}
                            >
                                <AddIcon />
                                Add Members
                            </Button>
                        </Box>
                    </Box>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Member Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Phone No.</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Referred companies</TableCell>
                                    <TableCell>Total Commission</TableCell>
                                    <TableCell>Referral Code</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows1.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">No member Found</TableCell>
                                    </TableRow>
                                ) : (
                                    rows1.map((row: any, i: number) => (
                                        <TableRow key={row?._id}>
                                            <TableCell align="center">{i + 1}</TableCell>
                                            <TableCell>
                                                <figure onClick={() => navigate(`/affiliate-members-details/${row?._id}`)} className="user_img">
                                                    <img style={{ borderRadius: 0 }} src={row?.image || `/static/images/user_placeholder.png`} alt="" />
                                                </figure>
                                            </TableCell>
                                            <TableCell>{row?.referenceId || "-"}</TableCell>
                                            <TableCell>{row?.name || "-"}</TableCell>
                                            <TableCell>{row?.phoneNo || "-"}</TableCell>
                                            <TableCell>
                                                {row?.email || "-"}
                                            </TableCell>
                                            <TableCell>{row?.totalReferredAgencies || "-"}</TableCell>
                                            <TableCell>{row?.totalReferredCommission ? "AED " + formatNumberWithCommas(String(row?.totalReferredCommission)) : "-"}</TableCell>
                                            <TableCell>
                                                {row?.referralCode || "-"}
                                            </TableCell>

                                            <TableCell>
                                                <Box className="table_actions">
                                                    <IconButton onClick={() => navigate(`/affiliate-members-details/${row?._id}`)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => navigate(`/affiliate-members-edit/${row?._id}`)}
                                                    >
                                                        <ModeEditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => {
                                                        setOpen(true);
                                                        setSelectedId(row?._id);
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
            {rows1?.length > 0 ? (
                <Pagination
                    setPage={setPage}
                    module={rows1}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                    limit={limit}
                    setLimit={setLimit}
                />
            ) : (
                ""
            )}
            <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteUser(selectedId)}
                name="Member"
            />
        </div>
    );
};

export default ManageAffiliateMembers;
