import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { firebaseCloudMessaging } from "./utils/firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { socket, Socket_URL } from "./utils/socket";
import { useAppSelector } from "./hooks/store";
import { getToken } from "./reducers/authSlice";

function App() {
  const navigate = useNavigate();
  const socketToken = useAppSelector(getToken);

  const handleNotificationClick = (item: any) => {
    if (item?.pushType === 8) {
      navigate("/manage-users");
    } else if (item?.pushType === 9) {
      navigate("/manage-agents");
    } else if (item?.pushType === 10) {
      navigate("/manage-company");
    } else if (item?.pushType === 28) {
      navigate("/manage-company-requests");
    } else if (item?.pushType === 24 || item?.pushType === 25) {
      navigate("/manage-property-requests");
    } else if (item?.pushType === 30) {
      navigate("/manage-property-reports");
    } else if (item?.pushType === 31) {
      navigate("/listing-action-tracker");
    } else if (item?.pushType === 7) {
      navigate("/property-listing");
    } else if (item?.pushType === 32) {
      navigate("/manage-enqueries");
    } else if (item?.pushType === 33) {
      navigate("/manage-feedbacks");
    } else {
      navigate("/recieved-notifications");
    }
  };

  function onConnect() {
    console.log("connected");
  }

  function onDisconnect() {
    console.log("disconnected");
  }

  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  useEffect(() => {
    if (socketToken) {
      const modifiedURL = `${Socket_URL}?token=${socketToken || ""}`;
      socket.io.uri = modifiedURL;
      if (!socket?.connected) {
        socket.connect();
      }
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);

      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    } else {
      socket.disconnect();
    }
  }, [socketToken]);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("FCM Token:", fcmToken);
        }
      } catch (error) {
        console.error("Error initializing FCM:", error);
      }
    }

    setToken();

    const handleMessage = (event: MessageEvent) => {
      try {
        event?.source?.postMessage("Hi client");
        toast.info(
          event?.data?.notification?.title || "notification received",
          {
            action: (
              <button
                style={{
                  marginLeft: "auto",
                  background: "transparent",
                  border: "none",
                  color: "#007bff",
                  cursor: "pointer",
                  minWidth: "150px",
                }}
                onClick={() =>
                  handleNotificationClick(
                    JSON.parse(event?.data?.data?.payload)
                  )
                }
              >
                View
              </button>
            ),
          }
        );
      } catch (error) {
        console.error("Error handling message:", error);
      }
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", handleMessage);
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((notificationPerm) => {
          notificationPerm.onchange = () => {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", handleMessage);
      }
    };
  }, []);

  return <Routing />;
}

export default App;
