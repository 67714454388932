import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import ManageReels from "./reels";
import ReelsDetails from "./reels/details";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import AddAmenities from "./amenities/add";
import AgentChatInbox from "../features/agents/agentInbox";
import RecievedNotifications from "./manageNotification/receivedNotification";
import CompanyMessages from "../features/company/companyMessage";
import ChangePassword from "./auth/changePassword";

import ManageChallenges from "./ManageAgents";
import AddChallenge from "./ManageAgents/add";
import RecipesDetails from "./ManageAgents/details";
import ManageProducts from "./manageSubScription";
import AddProducts from "./manageSubScription/add";

import ProductDetails from "./manageSubScription/details";


import ManageRecipes from "./ManageAgents";
import AddRecipes from "./ManageAgents/add";
import ManageSubscription from "./manageSubScription";
import SubscriptionDetails from "./manageSubScription/details";

import Addsubscription from "./manageSubScription/add";



import AddAgents from "./ManageAgents/add";
import { ChatDetails } from "../features";
import AgentChatDetails from "../features/agents/AgentChat";
import AddCategory from "./categories/add";
import CategoryDetails from "./categories/details";
import ManageCategory from "./categories";
import AddProperty from "./propertylisting/add";
import ManageProperty from "./propertylisting";
import PropertyDetails from "./propertylisting/details";
import ManageAds from "./ads";
import AdsDetails from "./ads/details";
import AddAds from "./ads/add";
import ManageChat from "./chat";
import IndexChatDetails from "./chat/details";
import Settings from "./settings";
import AgentDetail from "./ManageAgents/details";
import ManageRevenue from "./revenue";
import AgentReelById from "../features/agents/agentReelById";
import AddBoost from "./boost/add";
import CompanyReelById from "../features/company/CompanyReelsById";
import ManageBoost from "./boost";
import BoostDetails from "./boost/details";
import ManageCompany from "./company";
import AddCompany from "./company/add";
import CompanyDetails from "./company/details";
import AddCompanySubscription from "./companysybscription/add";
import ManageCompanySubscription from "./companysybscription";
import CompanySubscriptionDetails from "./companysybscription/details";
import AddBanners from "./banners/add";
import BannersDetails from "./banners/details";
import ManageBanners from "./banners";
import userTokenHistoryDetails from "../features/users/userTokenHistoryDetails";
import AgentTokenHistoryDetails from "../features/agents/agentTokenHistory";
import CompanyProperty from "../features/company/property";
import CompanyPropertyDetails from "../features/company/companyPropertyDetails";
import UserPropertyDetails from "../features/users/userpropertydetails";
import AgentProperty from "../features/agents/Property";
import AgentNowPropertyDetails from "../features/agents/agentPropertyDetails";
import ManageEmails from "./receivedEmails";
import ManageAmenities from "./amenities";
import AddPropertyType from "./PropertyType/add";
import ManagePropertyType from "./PropertyType";
import UserChatInbox from "../features/users/userChatInbox";
import CompanyChatInbox from "../features/company/companyinvox";
import PropertyMessages from "../features/Property/PropertyMessage";
import PropertyChatInbox from "../features/Property/ChatInbox";
import ManageListing from "./manageListing";
import ManagePropertyStatus from "./PropertyStatus";
import AddPropertyStatus from "./PropertyStatus/add";
import ListingPerformanceDashboard from "./ListingPerformanceDashboard";
import ManageSuperDashboard from "./superAgentDashboard";
import ManageFeedbacks from "./manageFeedbacks";
import ManageCompanyRequests from "./ManageCompanyRequest";
import CompanyRequestDetails from "./ManageCompanyRequest/details";
import PropertyRequestDetails from "./ManagePropertyRequest/details";
import ManagePropertyRequests from "./ManagePropertyRequest";
import WishlistPropertyDetails from "./users/details/wishlistDetails";
import userChatInboxDetails from "./users/details/chatDetails";
import UserEmailInboxDetails from "./users/details/emailDetails";
import ManageEnqeries from "./manageEnqueries";
import ManageNewsLetterEmails from "./newsletterEmails";
import ManagePropertyReports from "./managePropertyReports";
import PropertyReqDetails from "./managePropertyReports/details";
import ListingActionTracker from "./listingActionTracker";
import PropertyDetailsActionTraker from "./listingActionTracker/details";
import ManageAffiliateMembers from "./affiliateMembers/ManageMembers";
import AddAffiliateMembers from "./affiliateMembers/ManageMembers/addEditMembers";
import MemberDetails from "./affiliateMembers/ManageMembers/details";
import ManagePaymentRequest from "./affiliateMembers/paymentRequests";
import ShortTermRentals from "./ShortTermRentals";
import ShortTermRentalDetails from "./ShortTermRentals/details";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AddCategory,
  ManageCategory,
  LoginPage,
  DashBoardPage,
  CompanyMessages,
  ManageUsers,
  CompanyChatInbox,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  PropertyChatInbox,
  RecievedNotifications,
  ChangePassword,
  ManageChallenges,
  AddChallenge,
  RecipesDetails,
  ManageProducts,
  AddProducts,
  ProductDetails,
  UserChatInbox,
  ManageRecipes,
  AddRecipes,
  ManageSubscription,
  SubscriptionDetails,
  Addsubscription,
  AgentChatInbox,
  AddAgents,
  ChatDetails,
  CategoryDetails,
  AddProperty,
  ManageProperty,
  PropertyDetails,
  ManageAds,
  AddAds,
  AdsDetails,
  AgentChatDetails,
  ManageChat,
  IndexChatDetails,
  Settings,
  AgentDetail,
  ManageRevenue,
  PropertyMessages,
  AddBoost,
  ManageBoost,
  BoostDetails,
  ManageCompany,
  CompanyDetails,
  AddCompany,
  AddCompanySubscription,
  ManageCompanySubscription,
  CompanySubscriptionDetails,
  AddBanners,
  BannersDetails,
  ManageBanners,
  userTokenHistoryDetails,
  AgentTokenHistoryDetails,
  CompanyProperty,
  CompanyPropertyDetails,
  UserPropertyDetails,
  AgentProperty,
  AgentNowPropertyDetails,
  ManageEmails,
  AddAmenities,
  ManageAmenities,
  AddPropertyType,
  ManagePropertyType,
  ManageReels,
  ReelsDetails,
  AgentReelById,
  CompanyReelById,
  ManageSuperDashboard,
  ManageListing,
  ManagePropertyStatus,
  AddPropertyStatus,
  ListingPerformanceDashboard,
  ManageFeedbacks,
  ManageCompanyRequests,
  CompanyRequestDetails,
  PropertyRequestDetails,
  ManagePropertyRequests,
  WishlistPropertyDetails,
  userChatInboxDetails,
  UserEmailInboxDetails,
  ManageEnqeries,
  ManageNewsLetterEmails,
  ManagePropertyReports,
  PropertyReqDetails,
  ListingActionTracker,
  PropertyDetailsActionTraker,
  ManageAffiliateMembers,
  AddAffiliateMembers,
  MemberDetails,
  ManagePaymentRequest,
  ShortTermRentals,
  ShortTermRentalDetails

  
};
