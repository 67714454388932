import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetRequestByIdQuery } from "../../services/companyRequest";

const ShortTermRentalDetails = () => {
  const location = useLocation();

  const { _id } = useParams();
  const navigate = useNavigate();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);

  const [fetchApi, { isLoading }] = useLazyGetRequestByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);






  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h2 className="mn_hdng">Short Term Rental Details</h2>
            </Grid>
            <Grid item>
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/short-term-rentals")}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">First Name</Typography>
                      <Typography component="p">{ByIdDetails?.firstName || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Last Name</Typography>
                      <Typography component="p">{ByIdDetails?.lastName || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company Email</Typography>
                      <Typography component="p">{ByIdDetails?.email || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company Phone </Typography>
                      <Typography component="p">
                        {ByIdDetails?.dialCode && ByIdDetails?.phoneNo
                          ? `${ByIdDetails.dialCode}${ByIdDetails.phoneNo}`
                          : ''}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company Name</Typography>
                      <Typography component="p">{ByIdDetails?.companyName || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Number of agents</Typography>
                      <Typography component="p">{ByIdDetails?.noOfAgents || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Estimate listing per month</Typography>
                      <Typography component="p">{ByIdDetails?.estimateListingPerMonth || "-"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ShortTermRentalDetails;
