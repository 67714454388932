import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { ImageModal } from "../../../components";
import MainContainer from "../../../layout/MainContainer";

const WishlistPropertyDetails = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h2 className="mn_hdng">Property Details</h2>
            </Grid>
            <Grid item>
              <Button
                className="btn btn_primary"
                onClick={() =>
                  navigate(`/manage-users/details/${state?.id}`, { state: 3 })
                }
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Grid container spacing={3}>
                      {state?.data?.images?.length > 0 && (
                        <Grid item xs={12}>
                          <Typography component="h5">Images</Typography>
                          <Grid container spacing={2}>
                            {state?.data.images.map(
                              (image: string, index: number) => (
                                <Grid
                                  item
                                  lg={2}
                                  md={3}
                                  sm={4}
                                  xs={6}
                                  key={index}
                                >
                                  <figure className="profile_img">
                                    <img
                                      style={{ borderRadius: 0, width: "100%" }}
                                      src={image}
                                      alt={`property-${index}`}
                                    />
                                    <div className="overlay">
                                      <img
                                        onClick={() => handleOpenModal(image)}
                                        src="/static/images/visibility.png"
                                        alt="img"
                                      />
                                    </div>
                                  </figure>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      )}
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>
                            Property Name
                          </Typography>
                          <Typography component="p">
                            {state?.data?.name || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5" style={{ paddingTop: 10 }}>
                            Owner
                          </Typography>
                          <Typography component="p">
                            {state?.data?.users?.name || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p">
                            {state?.data?.price || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Category</Typography>
                          <Typography component="p">
                            {state?.data?.categoryName || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Property Type</Typography>
                          <Typography component="p">
                            {state?.data?.propertyType?.name || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Beds</Typography>
                          <Typography component="p">
                            {state?.data?.beds || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Baths</Typography>
                          <Typography component="p">
                            {state?.data?.baths || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Address</Typography>
                          <Typography component="p">
                            {state?.data?.address || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Key Features</Typography>
                          <Typography component="p">
                            {state?.data?.keyFeature || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Specifications</Typography>
                          <Typography component="p">
                            {state?.data?.specifications || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      {state?.data?.amenities?.length > 0 && (
                        <Grid item xs={12}>
                          <Typography
                            component="h5"
                            style={{
                              fontWeight: 600,
                              margin: "10px",
                            }}
                          >
                            Amenities
                          </Typography>
                          <Grid container spacing={2}>
                            {state?.data?.amenities?.map(
                              (amenity: any, index: number) => (
                                <Grid
                                  item
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  key={index}
                                >
                                  <Typography component="p">
                                    {" "}
                                    {index + 1}
                                    {". "}
                                    {amenity?.name}
                                  </Typography>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <ImageModal
        open={openModal}
        handleClose={handleCloseModal}
        image={selectedImage}
      />
    </div>
  );
};

export default WishlistPropertyDetails;
