import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchBar from "../../components/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  useEditBoostProfileMutation,
  useLazyDeleteByIdBoostProfileQuery,
  useLazyGetAllBoostProfileQuery,
  useLazyGetSpecificBoostProfileByIdQuery,
} from "../../services/boostProfile";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { errorToast, successToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
const ManageBoost = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [rows1, setRows1] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [ByIdDetails, setByIdDetails] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [fetchById] = useLazyGetSpecificBoostProfileByIdQuery();
  const [deleteBoostById] = useLazyDeleteByIdBoostProfileQuery();
  const [BoostList, { isLoading }] = useLazyGetAllBoostProfileQuery();
  const [editBoostProfilebyId] = useEditBoostProfileMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows1([]);
  };
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await BoostList({
        page: page,
        search: search,
        limit: limit,
      }).unwrap();
      if (response.statusCode === 200) {
        setRows1(response?.data?.boostProfile || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteBoost = async (userId: any) => {
    try {
      const response = await deleteBoostById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Boost deleted successfully");
        fetchData(page, searchTerm);
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchById({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm, limit]);

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Boost Plan</h1>
        </div>

        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <Box className="cards_header_right">
              {/* <Button className="btn btn_primary">
                <FileDownloadIcon /> Export CSV
              </Button> */}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-boost/add");
                }}
              >
                <AddIcon />
                Add Boost
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Boost Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Validity</TableCell>
                  {/* <TableCell>Sub-Category Name</TableCell> */}
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.map((row: any, index: any) => (
                  <TableRow key={row._id}>
                    <TableCell align="center">{index + 1}</TableCell>

                    <TableCell>{row?.title}</TableCell>
                    <TableCell>AED {" " + row?.price || "-"}</TableCell>
                    <TableCell>{row?.validity}</TableCell>
                    {/* <TableCell>
        <Switch
          defaultChecked={!row.isBlocked}
          onChange={(e) => {
            const isChecked = !e.target.checked;
            changeBoostProfileStatus(row._id, isChecked);  
          }}
          size="small"
        />
      </TableCell> */}
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-boost/details/${row?._id}`)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-boost/edit/${row?._id}`)
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows1?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows1}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteBoost(selectedId)}
        name="Boost"
      />
    </div>
  );
};

export default ManageBoost;
