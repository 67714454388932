import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../helpers/toast";
import SearchBar2 from "../../components/SearchBar2";
import { isNumber, isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";

import { useDeleteByIdpropertyTypeMutation, useGetAllpropertyTypeQuery, useLazyGetAllpropertyTypeQuery } from "../../services/propertyType";
import { CommonBody, PropertyType } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAddSettingsMutation, useLazyGetAllSettingsQuery } from "../../services/settings";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface CommissionDetails {
  buySingleSideCommission: string;
  buyFeaturedPercentage: string;
  buyPremiumPercentage: string;
  rentSingleSideCommission: string;
  rentFeaturedPercentage: string;
  rentPremiumPercentage: string;
}


const ManagePropertyType = () => {

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [rows, setRows] = useState<PropertyType[]>([]);
  const [fetchAll, { isLoading }] = useLazyGetAllpropertyTypeQuery();
  const [deleteType] = useDeleteByIdpropertyTypeMutation();
  const [addSettings] = useAddSettingsMutation();
  const [getSettings] = useLazyGetAllSettingsQuery();
  const [value, setValue] = useState<number>(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [settings, setSettings] = useState<CommissionDetails>({
    buySingleSideCommission: "",
    buyFeaturedPercentage: "",
    buyPremiumPercentage: "",
    rentSingleSideCommission: "",
    rentFeaturedPercentage: "",
    rentPremiumPercentage: "",
  })

  const postPriceApi = async () => {

    const body = {
      buySingleSideCommission: Number(settings?.buySingleSideCommission) || 0,
      buyFeaturedPercentage: Number(settings?.buyFeaturedPercentage) || 0,
      buyPremiumPercentage: Number(settings?.buyPremiumPercentage) || 0,
      rentSingleSideCommission: Number(settings?.rentSingleSideCommission) || 0,
      rentFeaturedPercentage: Number(settings?.rentFeaturedPercentage) || 0,
      rentPremiumPercentage: Number(settings?.rentPremiumPercentage) || 0,
    }
    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      let response = await addSettings(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        //  fetchGetApi()
        successToast("Changes Updated Successfully");
      }
    } catch (e: any) {
      errorToast(e?.data?.message);
    }
  };

  const fetchGetApi = async () => {
    try {
      let response = await getSettings({}).unwrap();
      if (response?.statusCode === 200) {
        setSettings((prev) => ({
          buySingleSideCommission: String(response?.data?.buySingleSideCommission) || "",
          buyFeaturedPercentage: String(response?.data?.buyFeaturedPercentage) || "",
          buyPremiumPercentage: String(response?.data?.buyPremiumPercentage) || "",
          rentSingleSideCommission: String(response?.data?.rentSingleSideCommission) || "",
          rentFeaturedPercentage: String(response?.data?.rentFeaturedPercentage) || "",
          rentPremiumPercentage: String(response?.data?.rentPremiumPercentage) || "",
        }))
      }

    } catch (e: any) {
      console.error("Failed to fetch settings:", e);
      errorToast(e?.message);
    }
  };

  const { data, isError, isSuccess, isLoading: load } = useGetAllpropertyTypeQuery({
    page: page, search: debouncedSearchTerm.trim(), limit: 100
  })
  const handleDelete = async (userId: any) => {
    try {
      const response = await deleteType({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Property Type deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    fetchGetApi()
  }, [])

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.propertyType || []);
    }
  }, [isSuccess, data]);



  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Property Type</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <Box className="cards_header_right">

              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-propertyType/add");
                }}
              >
                <AddIcon />
                Add Property Type
              </Button>
            </Box>
          </Box>
          <Box className="custom_tabs" sx={{ marginTop: "10px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >

              <Tab label="Property Type " {...a11yProps(0)} />
              <Tab label="Criteria" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>

                      <TableCell>Property Type</TableCell>
                      <TableCell>Property Mode</TableCell>
                      {/* <TableCell>Premium</TableCell>
                      <TableCell>Featured</TableCell> */}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length == 0 ? (<TableRow>
                      <TableCell colSpan={12} align="center">
                        No property type found
                      </TableCell>
                    </TableRow>) :
                      (rows.map((row: any, index: any) => (
                        <TableRow key={row?._id}>
                          <TableCell align="center"> {(page - 1) * 10 + index + 1}</TableCell>

                          <TableCell>{row?.name || "-"}</TableCell>
                          <TableCell>{row?.propertyMode === 1 ? "Buy" : row?.propertyMode === 2 ? "Rent" :row?.propertyMode === 3 ?"Under Construction": "-"}</TableCell>
                          {/* <TableCell>{row?.premiumPercentage || '-'}</TableCell>
                          <TableCell>{row?.featuredPercentage || '-'}</TableCell> */}

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                style={{ background: row?.name === "Buy" || row?.name === "Rent" ? "grey" : "black" }}
                                disabled={row?.name === "Buy" || row?.name === "Rent" ? true : false}
                                onClick={() =>
                                  navigate(`/manage-propertyType/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                style={{ background: row?.name === "Buy" || row?.name === "Rent" ? "grey" : "black" }}
                                disabled={row?.name === "Buy" || row?.name === "Rent" ? true : false}
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Card className="cards">
                <CardContent sx={{ p: 1 }}>
                  <h3>Buy</h3>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Single Side Commission(%)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="longPrice"
                        variant="outlined"
                        inputProps={{ maxLength: 5 }}
                        fullWidth
                        placeholder="Enter here"
                        value={settings?.buySingleSideCommission}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setSettings((prev) => ({ ...prev, buySingleSideCommission: (val.target.value) }))
                          }
                        }}

                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Featured Percentage(%)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="shortPrice"
                        variant="outlined"
                        fullWidth
                        placeholder="Enter here"
                        inputProps={{ maxLength: 5 }}
                        value={settings.buyFeaturedPercentage}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setSettings((prev) => ({ ...prev, buyFeaturedPercentage: (val.target.value) }))
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Premium Percentage(%)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="shortPrice"
                        variant="outlined"
                        fullWidth
                        placeholder="Enter here"
                        inputProps={{ maxLength: 5 }}
                        value={settings.buyPremiumPercentage}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setSettings((prev) => ({ ...prev, buyPremiumPercentage: (val.target.value) }))
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <h3>Rent</h3>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Single Side Commission(%)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="longPrice"
                        variant="outlined"
                        inputProps={{ maxLength: 5 }}
                        fullWidth
                        placeholder="Enter here"
                        value={settings?.rentSingleSideCommission}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setSettings((prev) => ({ ...prev, rentSingleSideCommission: (val.target.value) }))
                          }
                        }}

                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Featured Percentage(%)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="shortPrice"
                        variant="outlined"
                        fullWidth
                        placeholder="Enter here"
                        inputProps={{ maxLength: 5 }}
                        value={settings.rentFeaturedPercentage}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setSettings((prev) => ({ ...prev, rentFeaturedPercentage: (val.target.value) }))
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">Premium Percentage(%)</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="shortPrice"
                        variant="outlined"
                        fullWidth
                        placeholder="Enter here"
                        inputProps={{ maxLength: 5 }}
                        value={settings.rentPremiumPercentage}
                        onChange={(val) => {
                          if (val.target.value === " ") {
                            return;
                          } else if (isNumber(val.target.value)) {
                            setSettings((prev) => ({ ...prev, rentPremiumPercentage: (val.target.value) }))
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div className="form_btn">
                    <Button size="small" className="btn btn_primary" style={{ minWidth: "55px" }} onClick={() => postPriceApi()}>
                      Save
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>

      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Property Type"
      />
    </div>
  );
};

export default ManagePropertyType;
